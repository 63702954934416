import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../assets/logo.png"
import '../css/home.css';
import buttonContainer from '../assets/btn_bg_01.png'

export const Home = () => {

    const [blink, setBlink] = useState(false);

    const navigate = useNavigate();

    const nextInsertInformations = () => {
        navigate('/care/InsertInformations')
    }

    const nextHeathFunctionalFoot = () => {
        navigate('/care/HeathFunctionalFoot')
    }

    useEffect(() => {
        let blinkTimeout = setInterval(() => {
            setBlink((prevBlink) => !prevBlink);
        }, 30000);

        return () => {
            clearInterval(blinkTimeout);
        };
    }, []);

    return (
        <main className='w-screen h-screen'>
            <div className='flex flex-col justify-between w-full h-full p-5 pt-[4vw]'>
                <div>
                    <div className='mx-auto aspect-square mb-[10vw] w-[20vw]'>
                        <img src={logo} alt='slowcare logo' />
                    </div>
                    <h2 className='mb-5 text-center font-[900] md:mb-[5vw] text-[5vw] ki:mb-[5vw]'>맞춤건강케어 <span className='text-sc-blue'>영(榮)케어</span></h2>
                    <h1 className='font-jalnan text-center md:leading-[13vw] text-[10vw] ki:leading-[13vw]'>
                        <span className='block'>스스로 <span className='text-sc-green'>내 건강</span>을</span>
                        <span className='block'>확인해보세요!</span>
                    </h1>
                </div>
                <div className='mb-[10vw]'>
                    <div className='relative mb-8 md:mb-20 ki:mb-[13vw]' onClick={nextInsertInformations}>
                        <img
                            src={buttonContainer}
                            alt='start button'
                            className={`${blink ? 'blinking-button' : ''} w-[70vw] mx-auto md:w-[60vw] ki:w-[60vw]`} />
                        <p className='absolute text-[8vw] text-center text-white -translate-x-1/2 -translate-y-1/2 font-jalnan top-1/2 left-1/2'>
                            시작하기!
                        </p>
                    </div>
                    <div className='flex justify-center w-full'>
                        <button
                            onClick={nextHeathFunctionalFoot}
                            className='outline-none cursor-none w-[70vw] h-[10vw] text-[4vw] border-[1px] text-sc-blue border-sc-blue rounded-full font-jalnan md:w-[60vw] md:h-[10vw] md:border-2 ki:w-[60vw] ki:h-[10vw] ki:border-2'>
                            건강기능식품전체보기
                        </button>
                    </div>
                </div>
            </div>
        </main>
    )
}