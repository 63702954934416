import axios from "axios";
import {baseUrl} from "../../base";

const signOut = (navigate, auth) => {
    axios.post(`${baseUrl}/v1/sign-out`)
        .then(value => {

            auth.login();
            navigate("/care/manager");
        })
        .catch(err => {
            console.log(err);
            auth.login();
            navigate("/care/manager");
        })
}

export {
    signOut
}