import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Component1, Component2, Component3, Component4, Component5, Component6, Component7, Component8 } from './HealthCheck/InsertInformation'
import axios from "axios";
import { baseUrl } from "../base";
import TimeoutRedirect from './TimeoutRedirect';
import img1 from '../assets/img_loading_01.png'
import img2 from '../assets/img_loading_02.png'
import img3 from '../assets/img_loading_03.png'
// import image1 from '../../../as sets/img_loading_03.png'

export const InsertInformation = () => {

    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState(1);
    const [condition, setCondition] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCurrentStep, setIsLoadingCurrentStep] = useState(0);
    const [apiSuccess, setApiSuccess] = useState(false);
    const [imgOrder, setImgOrder] = useState(1);

    useEffect(() => {
        /// 로딩 완료및 API 성공시 넘어감
        if (!isLoading && isLoadingCurrentStep === 2 && apiSuccess) {
            navigate('/care/healthresult', { state: { isMotion: true } });
        }
    }, [isLoading, isLoadingCurrentStep, navigate, apiSuccess]);

    const handleNextStep = async (key, fieldName, value) => {
        if (key > 9) { return }
        setCondition((prevResponses) => {
            const previousValues = prevResponses['check'] || [];
            const newValue = value.toString(); // 값을 문자열로 변환합니다.
            const firstDigit = newValue.charAt(0); // 첫 자리 숫자를 추출합니다.

            // 첫 자리 숫자와 관련된 값을 모두 제거합니다.
            const filteredValues = previousValues.filter((item) => item.charAt(0) !== firstDigit);

            // 새 값을 추가합니다.
            filteredValues.push(newValue);
            console.log(filteredValues);
            return { ...prevResponses, ['check']: filteredValues };
        });
        setCurrentStep(key);
    };

    const handlePreviousStep = (key) => {
        setCurrentStep(currentStep - 1);
    }

    useEffect(() => {
        const conditions = condition['check'] || [];
        if (conditions.length < 8) { return }
        if (Object.keys(condition).length === 0) { return }

        const form = JSON.stringify(condition['check']);
        axios.post(`${baseUrl}/v1/users`, { answers: form })
            .then(response => {
                const userId = response.data.userId;
                localStorage.setItem('userId', userId);
                setApiSuccess(true)
            })
            .catch(error => {
                console.error('There was a problem updating the product:', error);
            });

        setIsLoading(true);
    }, [condition]);

    const components = [
        <Component1 key={1} onNextStep={handleNextStep} />,
        <Component2 key={2} onNextStep={handleNextStep} />,
        <Component3 key={3} onNextStep={handleNextStep} />,
        <Component4 key={4} onNextStep={handleNextStep} />,
        <Component5 key={5} onNextStep={handleNextStep} />,
        <Component6 key={6} onNextStep={handleNextStep} />,
        <Component7 key={7} onNextStep={handleNextStep} />,
        <Component8 key={8} onNextStep={handleNextStep} />,
    ];

    const setTime = (key) => {
        const nextStep = setTimeout(() => {
            const value = key < 3 ? key : 2
            setIsLoadingCurrentStep(value);

            if (key === 1) {
                setImgOrder(2)
                return;
            }

            if (key === 2) {
                setImgOrder(3)
                return;
            }

            if (key === 3) {
                setIsLoading(false);
            }
        }, 1700);

        return () => {
            clearTimeout(nextStep);
        };
    }

    const moveHome = () => {
        navigate('/care')
    }

    return (
        <TimeoutRedirect redirectPath="/care">
            <main className='w-screen h-screen'>
                {isLoading ? (
                    <div className='relative w-screen h-screen text-center bg-sc-blue'>
                        <motion.div className='w-screen h-screen bg-white' initial={{ y: '43%' }} animate={{ y: '0%' }} transition={{ duration: 5 }}>
                        </motion.div>
                        <div className='absolute bottom-0 flex flex-col w-full items-center justify-center pb-20 -translate-x-1/2 left-1/2 ki:pb-[12vw]'>
                            {imgOrder === 1 && (
                                <>
                                    <img src={img1} alt='' className='w-[50vw]' onLoad={() => setTime(1)} />
                                    <span className='text-xl text-sc-blue font-jalnan sm:text-lg md:mb-2 md:text-4xl ki:mb-[5vw] ki:text-[5vw]'>분석중입니다</span>
                                    <span className='text-xl text-sc-blue font-jalnan sm:text-lg md:text-4xl ki:text-[5vw]'>잠시 기다려주세요</span>
                                </>
                            )}
                            {imgOrder === 2 && (
                                <>
                                    <img src={img2} alt='' className='w-[50vw]' onLoad={() => setTime(2)} />
                                    <span className='text-xl text-sc-blue font-jalnan sm:text-lg md:mb-2 md:text-4xl ki:mb-[5vw] ki:text-[5vw]'>거의 완료되었어요~</span>
                                    <span className='text-xl text-sc-blue font-jalnan sm:text-lg md:text-4xl ki:text-[5vw]'>조금만 기다주세요.</span>
                                </>
                            )}
                            {imgOrder === 3 && (
                                <>
                                    <img src={img3} alt='' className='w-[50vw]' onLoad={() => setTime(3)}
                                    />
                                    <span className='text-xl md:mb-2 md:text-4xl text-sc-blue font-jalnan sm:text-lg ki:mb-[5vw] ki:text-[5vw]'>완료되었습니다.</span>
                                    <span className='text-xl md:text-4xl text-sc-blue font-jalnan sm:text-lg ki:text-[5vw]'>맞춤 건강케어 정보를 확인하세요.</span>
                                </>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className='flex flex-col justify-between w-full h-full bg-sc-blue'>
                        <div className='h-full'>
                            <div className='flex justify-end items-center w-full h-16 pl-5 pr-5 md:pr-10 md:pl-10 md:h-36 ki:pr-[3.5vw] ki:pl-[3.5vw] ki:h-[17vw]'>
                                <div>
                                    <button className='outline-none cursor-none text-lg w-28 text-white rounded-full font-jalnan border-[1px] px-4 py-1 border-white sm:text-base md:border-2 md:w-[30vw] md:text-[4vw] md:h-[10vw] ki:border-4 ki:w-[30vw] ki:text-[4vw] ki:h-[10vw]' onClick={moveHome}>처음으로</button>
                                </div>
                            </div>
                            <div>
                                {components[currentStep - 1]}
                            </div>
                        </div>
                        <div className='flex h-full'>
                            {currentStep === 1 ?
                                (<div></div>)
                                : <button className="outline-none cursor-none bottom-0 absolute flex pb-5 pl-5 text-xl text-white font-jalnan md:pb-16 md:pl-20 md:text-[5vw] ki:pb-[8vw] ki:pl-[6vw] ki:text-[5vw]" onClick={() => handlePreviousStep(currentStep)} > &lt; 이전으로</button>}
                        </div>
                    </div>
                )}
            </main>
        </TimeoutRedirect>
    );
} 