import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from './Navigation';
import '../../css/HealthDetailCheckMain.css';
import {
  HealthDetailCheckSection1,
  HealthDetailCheckSection2,
  HealthDetailCheckSection3,
  HealthDetailCheckSection4,
  HealthDetailCheckSection5,
  HealthDetailCheckSection6,
  HealthDetailCheckSection7,
  HealthDetailCheckSection8,
  HealthDetailCheckSection9,
  HealthDetailCheckSection10
} from './Section'
import { TopNavigationBar } from './TopNavigationBar';
import TimeoutRedirect from '../TimeoutRedirect';

export const HealthDetailCheckMain = () => {

  const navigate = useNavigate();

  // 각 섹션이 완료되었는지를 추적하기 위한 상태
  const [completedSections, setCompletedSections] = useState({
    health_01: false,
    health_02: false,
    health_03: false,
    health_04: false,
    health_05: false,
    health_06: false,
    health_07: false,
    health_08: false,
    health_09: false,
    health_10: true
  });

  const [orders, setOrders] = useState([]);

  const [allResponses, setAllResponses] = useState({});
  const [submitTrigger, setSubmitTrigger] = useState(false);

  const handleSectionComplete = (sectionId, responses, isCompleted) => {
    // 해당 섹션이 완료되었다는 것을 상태에 반영
    setCompletedSections({
      ...completedSections,
      [sectionId]: isCompleted,
    });
    // 서버에 응답을 제출하거나 다음 단계로 넘어가는 로직을 여기에 추가
    setAllResponses((prevAllResponses) => ({
      ...prevAllResponses,
      [sectionId]: responses
    }));

    setSubmitTrigger(true);

  };

  useEffect(() => {
    if (submitTrigger) {
      setSubmitTrigger(false); // Reset the trigger
    }
  }, [submitTrigger, allResponses]); // Run the effect when 'submitTrigger' changes

  const [currentSection, setCurrentSection] = useState(1);
  const totalSections = 10;

  // 이전 섹션으로 이동
  const prevSection = () => {
    if (currentSection > 1) {
      window.scrollTo({ top: 0 });
      const sectionKey = `health_${currentSection < 10 ? `0${currentSection}` : currentSection}`;

      // 현재 섹션의 응답을 빈 값으로 설정
      setAllResponses(prevAllResponses => ({
        ...prevAllResponses,
        [sectionKey]: {} // 빈 객체로 섹션의 응답을 초기화
      }));

      if (currentSection == 2) {
        setCurrentSection(1);
      } else {
        const currentOrderIndex = orders.indexOf(currentSection);
        const prevSectionOrder = orders[currentOrderIndex - 1];

        if (prevSectionOrder > 2) {
          setCurrentSection(prevSectionOrder);
        } else if (prevSectionOrder === 2) {
          setOrders([]);
          setCurrentSection(2);
        }
      }
    }
    window.scrollTo(0, 0);
  };

  const orderListCheck = (responses) => {
    const ordersddd = [1, 2];

    const section1 = responses['health_01'];
    const section2 = responses['health_02'];

    let sumOfLastDigits = 0;
    for (let i = 4; i <= 12; i++) {
      if (Array.isArray(section2[i])) {
        section2[i].forEach(response => {
          sumOfLastDigits += parseInt(response.slice(-2));
        });
      }
    }

    // 체크: 305, 306, 401~1205의 일의 자리 합
    if (section1[3].includes('305') || section1[3].includes('306') || sumOfLastDigits >= 25) {
      ordersddd.push(3);
    }

    // 체크: 1303, 1304, 1305, 1403, 1404
    if (section2[13] && ['1303', '1304', '1305'].some(code => section2[13].includes(code))) {
      ordersddd.push(4);
    } else if (section2[14] && ['1403', '1404'].some(code => section2[14].includes(code))) {
      ordersddd.push(4);
    }


    // 체크: 1502, 1503, 1504, 1602, 1702
    if (section2[15].includes('1502')) { ordersddd.push(5) };
    if (section2[15].includes('1503')) { ordersddd.push(6) };
    if (section2[15].includes('1504')) { ordersddd.push(7) };
    if (section2[16].includes('1602')) { ordersddd.push(8) };
    if (section2[17].includes('1702')) { ordersddd.push(9) };

    // 마지막 섹션 추가
    ordersddd.push(10);

    return ordersddd;
  }

  // 다음 섹션으로 이동
  const nextSection = () => {
    // 현재 섹션이 완료되었는지 체크하는 로직
    const sectionKey = currentSection < 10 ? `health_0${currentSection}` : `health_${currentSection}`;

    if (completedSections[sectionKey]) {
      window.scrollTo({ top: 0 });

      // 모든 문항이 체크되었으면 다음 섹션으로 이동
      if (currentSection === 10) {
        navigate('/care/healthdetailresult', {
          state: {
            isMotion: true,
            allResponses: allResponses
          }
        });
      } else if (currentSection < totalSections) {
        if (currentSection === 1) {
          setCurrentSection(2);
        } else if (currentSection === 2) {
          const orderlist = orderListCheck(allResponses);
          setOrders(orderlist);
          setCurrentSection(orderlist[2]);
        } else {
          const currentOrderIndex = orders.indexOf(currentSection);
          setCurrentSection(orders[currentOrderIndex + 1]);
        }
        window.scrollTo(0, 0);
      }
    } else {
      // 문항이 체크되지 않았으면 알림
      alert("모든 질문에 답변해야 다음 화면으로 이동됩니다.");
    }
  };

  return (
    <TimeoutRedirect redirectPath="/care">
      <main className='flex flex-col justify-between w-screen h-screen'>
        <div className='pt-16 pb-20 md:pt-32 md:pb-[20vw] ki:pt-[17vw] ki:pb-[20vw]'>
          <div>
            <TopNavigationBar />
          </div>

          <div className='overflow-y-auto h-[calc(100vh-144px] md:h-[calc(100vh - 128px - 20vw] ki:h-[calc(100vh - 17vw - 20vw]'>
            {currentSection === 1 && (
              <HealthDetailCheckSection1
                onSectionComplete={handleSectionComplete}
                existingResponses={allResponses['health_01']}
              />
            )}

            {currentSection === 2 && (
              <HealthDetailCheckSection2
                onSectionComplete={handleSectionComplete}
                existingResponses={allResponses['health_02']}
              />
            )}

            {currentSection === 3 && (
              <HealthDetailCheckSection3
                onSectionComplete={handleSectionComplete}
                existingResponses={allResponses['health_03']}
              />
            )}

            {currentSection === 4 && (
              <HealthDetailCheckSection4
                onSectionComplete={handleSectionComplete}
                existingResponses={allResponses['health_04']}
              />
            )}

            {currentSection === 5 && (
              <HealthDetailCheckSection5
                onSectionComplete={handleSectionComplete}
                existingResponses={allResponses['health_05']}
              />
            )}

            {currentSection === 6 && (
              <HealthDetailCheckSection6
                onSectionComplete={handleSectionComplete}
                existingResponses={allResponses['health_06']}
              />
            )}

            {currentSection === 7 && (
              <HealthDetailCheckSection7
                onSectionComplete={handleSectionComplete}
                existingResponses={allResponses['health_07']}
              />
            )}

            {currentSection === 8 && (
              <HealthDetailCheckSection8
                onSectionComplete={handleSectionComplete}
                existingResponses={allResponses['health_08']}
              />
            )}

            {currentSection === 9 && (
              <HealthDetailCheckSection9
                onSectionComplete={handleSectionComplete}
                existingResponses={allResponses['health_09']}
              />
            )}

            {currentSection === 10 && (
              <HealthDetailCheckSection10
                onSectionComplete={handleSectionComplete}
                existingResponses={allResponses['health_10']}
              />
            )}

          </div>
          <Navigation
            onPrev={prevSection}
            onNext={nextSection}
          />
        </div>
      </main>
    </TimeoutRedirect>
  );
};
