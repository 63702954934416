import React, { useState, useEffect } from 'react';
import './MobileLinkInfo.css';
import logo from '../../assets/logo.png';
import profileImage from '../../assets/img_loading_02.png'
import { MobileProgramSection, MobileFoodSection, MobileProductSection } from './component';
import axios from 'axios';
import { baseUrl } from '../../base';
import {useParams} from "react-router-dom";

const MobileLinkInfo = () => {
    const { userId } = useParams();
    const currentURL = window.location.href;

    // const allResponses = location.state.allResponses;
    // const scoreValues = HealthDetailSymptomScores(allResponses);
    // const sortedScoreValues = scoreValues.sort((a, b) => b.value - a.value);

    const [result, setResult] = useState({
        symptom1: {
            id: 0,
            name: "",
            keyword: "",
            description: "",
            recommendFood: "",
            avoidFood: "",
            overall: "",
            products: [
                {
                    id: 0,
                    name: "",
                    description: "",
                    keyword: "",
                    link: "",
                    url: "",
                },
                {
                    id: 0,
                    name: "",
                    description: "",
                    keyword: "",
                    link: "",
                    url: "",
                }
            ],
            programs : [
                {
                    url: ""
                },
                {
                    url: ""
                }
            ]
        },
        symptom2: {
            id: 0,
            name: "",
            keyword: "",
            description: "",
            recommendFood: "",
            avoidFood: "",
            overall: "",
            products: []
        },
    });

    useEffect(() => {

        axios.get(`${baseUrl}/v1/users/${userId}/detail-result`)
            .then(response => {
                if(response.data.length === 0) {
                    axios.get(`${baseUrl}/v1/users/${userId}/simple-result`)
                        .then(response2 => {
                            setResult(response2.data);
                        })
                } else {
                    setResult(response.data);
                }
            })
            .catch(error => {
                console.error('There was a problem fetching program data:', error);
            });
    }, [])

    return (
        <main className='w-screen h-screen'>
            <div className='flex flex-col w-full h-full p-7 sm:px-3 md:pt-10 md:pb-10 ki:pt-[5vw] ki:pb-[5vw]'>
                <div>
                    <div className='w-20 mx-auto aspect-square md:w-[20vw] mb-[2vw] ki:w-[20vw]'>
                        <img src={logo} alt='slowcare logo' />
                    </div>
                    <h2 className='mb-8 text-lg text-center font-jalnan md:text-[5vw] md:mb-[5vw] ki:text-[5vw] ki:mb-[5vw]'>맞춤건강케어 <span className='text-sc-blue'>슬로우케어</span></h2>
                </div>

                <MobileProgramSection programs={result.symptom1.programs} />
                <div className="flex justify-center w-full pb-12 pt-7 md:pt-14 md:pb-14 ki:pt-[7vw] ki:pb-[7vw]">
                    <div className="self-center h-[10px] w-12 bg-sc-blue2 md:w-24 md:h-4 ki:w-[10vw] ki:h-[1.5vw] "></div>
                </div>
                <MobileProductSection products={result.symptom1.products} />
                <div className="flex justify-center w-full pb-12 pt-7 md:pt-14 md:pb-14 ki:pt-[7vw] ki:pb-[7vw]">
                    <div className="self-center h-[10px] w-12 bg-sc-blue2 md:w-24 md:h-4 ki:w-[10vw] ki:h-[1.5vw] "></div>
                </div>
                <MobileFoodSection symptom={result.symptom1} />
            </div>
        </main>
    );
};

export default MobileLinkInfo;