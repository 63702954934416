import { motion, progress } from 'framer-motion';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import buttonContainer1 from '../../assets/btn_bg_03.png'
import buttonContainer2 from '../../assets/btn_bg_02.png'
import '../../css/HealthDetailCheckResult.css';
import { HealthDetailSymptomScores } from '../HealthDetailCheck'
import {
    HealthDetailResultFoodSection,
    HealthDetailResultSymptomSection,
    HealthDetailResultProductSection,
    HealthDetailResultProgramSection
} from './component'
import { HealthDetailCheckResultTopNavigationBar } from './HealthDetailCheckResultTopNavigationBar';
import axios from 'axios';
import { baseUrl } from '../../base';
import TimeoutRedirect from '../TimeoutRedirect';

export const HealthDetailCheckResult = () => {

    const navigate = useNavigate();

    const userId = localStorage.getItem('userId');

    const foodRef = useRef(null);
    const symptomRef = useRef(null);
    const productRef = useRef(null);

    const [foodHeight, setFoodHeight] = useState();
    const [symptomHeight, setSymptomHeight] = useState();
    const [productHeight, setProductHeight] = useState();

    const [currentView, setCurrentView] = useState(0);
    const [blink, setBlink] = useState(false);
    const [viewCompleted, setViewCompleted] = useState(false);
    const [loading, setLoading] = useState(true);

    const location = useLocation();
    const isMotion = location.state.isMotion;
    const allResponses = location.state.allResponses ?? [];
    const { answers, scoreValues } = HealthDetailSymptomScores(allResponses);
    const sortedScoreValues = scoreValues.sort((a, b) => b.value - a.value);
    const [result, setResult] = useState({
        symptom1: {
            id: 0,
            name: "",
            keyword: "",
            description: "",
            recommendFood: "",
            avoidFood: "",
            overall: "",
            products: [
                {
                    id: 0,
                    name: "",
                    description: "",
                    keyword: "",
                    link: "",
                    url: "",
                }
            ]
        },
        symptom2: {
            id: 0,
            name: "",
            keyword: "",
            description: "",
            recommendFood: "",
            avoidFood: "",
            overall: "",
            products: []
        },
    });
    const [programs, setPrograms] = useState([
        {
            url: ""
        }
    ]);

    const handleClick = () => {
        setCurrentView((prev) => prev + 1);
    };

    const navigateToPoint = () => {
        navigate('/care/send', { state: { isDetail: true } });
    }

    useEffect(() => {
        let blinkTimeout = null;

        if (currentView < 4) {
            blinkTimeout = setInterval(() => {
                setBlink((prevBlink) => !prevBlink);
            }, 5000);
        }

        if (currentView < 3) return;
        setViewCompleted(true)

        return () => {
            clearInterval(blinkTimeout);
        };
    }, [currentView]);

    useEffect(() => {
        if (isMotion) {
            const sortedScoreValueIds = sortedScoreValues.slice(0, 2).map(value => value.id);

            axios.put(`${baseUrl}/v1/users/${userId}/detail-check`, { answers: JSON.stringify(answers), symptomIds: sortedScoreValueIds })
                .then(response => {
                    axios.get(`${baseUrl}/v1/users/${userId}/detail-result`)
                        .then(response => {
                            const { data } = response;
                            let insertData = [];
                            data.symptom1.programs.forEach(value => {
                                if (programs.id !== undefined) {
                                    insertData = programs;
                                }
                                insertData.push(value);
                            });
                            data.symptom2.programs.forEach(value => {
                                if (programs.id !== undefined) {
                                    insertData = programs;
                                }
                                insertData.push(value);
                            });
                            setPrograms(insertData);
                            setResult(data);
                        })
                        .catch(error => {
                            console.error('There was a problem fetching program data:', error);
                        });
                })
                .catch(error => {
                    console.error('There was a problem fetching program data:', error);
                });
        } else {
            axios.get(`${baseUrl}/v1/users/${userId}/detail-result`)
                .then(response => {
                    const { data } = response;
                    let insertData = [];
                    data.symptom1.programs.forEach(value => {
                        if (programs.id !== undefined) {
                            insertData = programs;
                        }
                        insertData.push(value);
                    });
                    data.symptom2.programs.forEach(value => {
                        if (programs.id !== undefined) {
                            insertData = programs;
                        }
                        insertData.push(value);
                    });
                    setPrograms(insertData);
                    setResult(data);
                })
                .catch(error => {
                    console.error('There was a problem fetching program data:', error);
                });
        }
    }, [])


    useEffect(() => {
        const timer = setTimeout(() => {
            if (!foodRef || !productRef || !symptomRef) return;

            const foodHeight = foodRef.current.offsetHeight;
            const symptomHeight = symptomRef.current.offsetHeight;
            const productHeight = productRef.current.offsetHeight;

            const totalHeigt = foodHeight + symptomHeight;
            const totalHeigt2 = totalHeigt + productHeight;

            setFoodHeight(foodHeight)
            setSymptomHeight(totalHeigt)
            setProductHeight(totalHeigt2)

            setLoading(false);
            setCurrentView(1);
        }, 4000)

        return () => {
            clearTimeout(timer);
        };
    }, [result])

    return (
        <TimeoutRedirect redirectPath="/care">
            <main className={`w-screen h-screen`}>
                <div className="flex flex-col w-full h-full">
                    <HealthDetailCheckResultTopNavigationBar />
                    {isMotion ? (
                        <div
                            className={
                                `${(blink && !viewCompleted) ? 'shake' : ''} w-full px-7 pt-[64px] pb-[80px] h-[calc(100vh - 144px)] sm:pl-3 sm:pr-3 md:pl-10 md:pr-10 md:pt-[20vw] md:h-[calc(100vh - 40vw)] md:pb-[20vw] ki:pl-[5vw] ki:pr-[5vw] ki:pt-[20vw] ki:h-[calc(100vh - 40vw)] ki:pb-[20vw]`
                            }
                        >
                            <motion.div
                                initial={{
                                    y: currentView === 0 ? '-100%'
                                        : currentView === 1 ? '-100%'
                                            : currentView === 2 ? `calc(-100% + ${foodHeight}px)`
                                                : currentView === 3 ? `calc(-100% + ${symptomHeight}px)`
                                                    : `calc(-100% + ${productHeight}px`
                                }}
                                animate={{
                                    y: currentView === 0 ? '-100%'
                                        : currentView === 1 ? `calc(-100% + ${foodHeight}px)`
                                            : currentView === 2 ? `calc(-100% + ${symptomHeight}px)`
                                                : currentView === 3 ? `calc(-100% + ${productHeight}px)`
                                                    : '0%',
                                }}
                                transition={{
                                    duration: 2,
                                }}
                            >
                                <div>
                                    <HealthDetailResultProgramSection programs={programs} />
                                    <div className="flex justify-center w-full pb-7 pt-7 md:pt-14 md:pb-14 ki:pt-[7vw] ki:pb-[7vw]">
                                        <div className="self-center h-[10px] w-12 bg-sc-green md:w-24 md:h-4 ki:w-[10vw] ki:h-[1.5vw] "></div>
                                    </div>
                                </div>
                                <div ref={productRef}>
                                    <HealthDetailResultProductSection product={result.symptom1.products[0]} />
                                    <div className="flex justify-center w-full pb-7 pt-7 md:pt-14 md:pb-14 ki:pt-[7vw] ki:pb-[7vw]">
                                        <div className="self-center h-[10px] w-12 bg-sc-green md:w-24 md:h-4 ki:w-[10vw] ki:h-[1.5vw] "></div>
                                    </div>
                                </div>
                                <div ref={symptomRef}>
                                    <HealthDetailResultSymptomSection
                                        scoreValues={[result.symptom1, result.symptom2]}
                                        descriptions={[result.symptom1.description, result.symptom2.description]}
                                    />
                                    <div className="flex justify-center w-full pb-7 pt-7 md:pt-14 md:pb-14 ki:pt-[7vw] ki:pb-[7vw]">
                                        <div className="self-center h-[10px] w-12 bg-sc-green md:w-24 md:h-4 ki:w-[10vw] ki:h-[1.5vw] "></div>
                                    </div>
                                </div>
                                <div className="pb-16" ref={foodRef}>
                                    <HealthDetailResultFoodSection symptom={result.symptom1} />
                                </div>
                            </motion.div>
                        </div>
                    ) : (
                        <div
                            className={
                                `w-full px-7 pt-[64px] pb-[80px] h-[calc(100vh - 144px)] sm:pl-3 sm:pr-3 md:pl-10 md:pr-10 md:pt-[20vw] md:h-[calc(100vh - 40vw)] md:pb-[20vw] ki:pl-[5vw] ki:pr-[5vw] ki:pt-[20vw] ki:h-[calc(100vh - 40vw)] ki:pb-[20vw]`
                            }
                        >
                            <div>
                                <HealthDetailResultProgramSection programs={programs} />
                                <div className="flex justify-center w-full pb-7 pt-7 md:pt-14 md:pb-14 ki:pt-[7vw] ki:pb-[7vw]">
                                    <div className="self-center h-[10px] w-12 bg-sc-green md:w-24 md:h-4 ki:w-[10vw] ki:h-[1.5vw] "></div>
                                </div>
                            </div>
                            <div ref={productRef}>
                                <HealthDetailResultProductSection product={result.symptom1.products[0]} />
                                <div className="flex justify-center w-full pb-7 pt-7 md:pt-14 md:pb-14 ki:pt-[7vw] ki:pb-[7vw]">
                                    <div className="self-center h-[10px] w-12 bg-sc-green md:w-24 md:h-4 ki:w-[10vw] ki:h-[1.5vw] "></div>
                                </div>
                            </div>
                            <div ref={symptomRef}>
                                <HealthDetailResultSymptomSection
                                    scoreValues={[result.symptom1, result.symptom2]}
                                    descriptions={[result.symptom1.description, result.symptom2.description]}
                                />
                                <div className="flex justify-center w-full pb-7 pt-7 md:pt-14 md:pb-14 ki:pt-[7vw] ki:pb-[7vw]">
                                    <div className="self-center h-[10px] w-12 bg-sc-green md:w-24 md:h-4 ki:w-[10vw] ki:h-[1.5vw] "></div>
                                </div>
                            </div>
                            <div className="pb-16" ref={foodRef}>
                                <HealthDetailResultFoodSection symptom={result.symptom1} />
                            </div>
                        </div>
                    )}

                    {loading && (
                        <div className="absolute text-center transform -translate-x-1/2 -translate-y-1/2 loading-text top-1/2 left-1/2">
                            <label className="mt-3 text-xl leading-tight text-sc-black font-jalnan md:text-5xl ki:text-[5vw]">잠시만 기다리세요.</label>
                        </div>
                    )}

                    <div className="fixed bottom-0 flex items-center justify-center w-full h-20 bg-sc-green2 md:h-[20vw] ki:h-[20vw] ">
                        <div>
                            {(currentView > 3 || !isMotion) ? (
                                <div className='relative' onClick={navigateToPoint}>
                                    <img
                                        src={buttonContainer2}
                                        alt='start button'
                                        className='w-[90vw] mx-auto'
                                    />
                                    <p className='absolute text-xl text-center text-white -translate-x-1/2 -translate-y-1/2 font-jalnan top-1/2 left-1/2 w-full sm:text-lg md:text-[6vw] ki:text-[6vw]'>
                                        내 폰으로 건강 정보 보내기
                                    </p>
                                </div>
                            ) : (
                                <div className={`relative`} onClick={handleClick}>
                                    <img
                                        src={buttonContainer1}
                                        alt="start button"
                                        className={`${(blink && !viewCompleted) ? 'blink' : ''} sm:w-[165px] w-[50vw] mx-auto`}
                                    />
                                    <p className="absolute text-xl text-center text-white -translate-x-1/2 -translate-y-1/2 font-jalnan top-1/2 left-1/2 w-full md:text-[7.5vw] ki:text-[7.5vw]">
                                        다음보기!
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </TimeoutRedirect>
    );
};