import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom'; // React Router v6 사용시
import './css/common.css';
import logoImage from './img/top_logo.png';
import axios from "axios";
import {baseUrl} from "../../base";
import {useAuth} from "../context";
import {signOut} from "./signout";

const AdminStats = () => {
    const auth = useAuth();



    const navigate = useNavigate(); // React Router v6 사용시
    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [selectedOption, setSelectedOption] = useState('all');
    const [searchValue, setSearchValue] = useState(null);
    const [totalSize, setTotalSize] = useState(0);
    const [isSearch, setIsSearch] = useState(false);
    const itemsPerPage = 10;
    useEffect(() => {
        axios.post(`${baseUrl}/v1/verify/access-token`)
            .then(res => {
                if(res.data) {
                    auth.login(res.data);
                }
            })
            .catch(err => {
                console.log(err);
                navigate("/care/manager");
            })
    },[]);

    useEffect(() => {
        if(isSearch && searchValue !== '') {
            fetchDataWithSearch();
        } else {
            fetchData();
        }
    }, [currentPage]);


    const fetchData = () => {
        axios.get(`${baseUrl}/v1/users?pageNumber=${currentPage}&windowSize=${itemsPerPage}&type=all&type2=all`)
            .then(response => {
                setUsers(response.data.rows);
                setTotalSize(response.data.totalCount);
                const totalPagesCount = Math.ceil(response.data.totalCount / itemsPerPage);
                setTotalPages(totalPagesCount);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const handleSearch = () => {
        fetchDataWithSearch();
        setCurrentPage(1);
        setIsSearch(true);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);
        const hours = `0${date.getHours()}`.slice(-2);
        const minutes = `0${date.getMinutes()}`.slice(-2);

        return `${year}.${month}.${day} (${hours}:${minutes})`;
    };

    const handleFilterChange = (event) => {
        setSelectedFilter(event.target.value);
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSearchInputChange = (event) => {
        setSearchValue(event.target.value);
        // You can perform additional logic here if needed based on the search value
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const fetchDataWithSearch = () => {
        const searchQuery = searchValue ? `&search=${searchValue}` : '';

        axios.get(`${baseUrl}/v1/users?search=${searchValue}&pageNumber=${currentPage}&windowSize=${itemsPerPage}&type=${selectedFilter}&type2=${selectedOption}`)
            .then(response => {
                setUsers(response.data.rows);
                setTotalSize(response.data.totalCount);
                const totalPagesCount = Math.ceil(response.data.totalCount / itemsPerPage);
                setTotalPages(totalPagesCount);
                setCurrentPage(1);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const renderPageButtons = () => {
        const pageButtons = [];
        const maxVisiblePages = 5; // 최대 표시할 페이지 수

        let startPage = Math.max(1, Math.min(currentPage - Math.floor(maxVisiblePages / 2), totalPages - maxVisiblePages + 1));
        let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

        for (let i = startPage; i <= endPage; i++) {
            pageButtons.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={currentPage === i ? 'active' : ''}
                >
                    {i}
                </button>
            );
        }

        return pageButtons;
    };

    return (
        <div>
            <div id="top_area">
                <div class="top_logo"><img src={logoImage} alt="top logo" /></div>
                <div class="topmenu_all">
                    <button onClick={() => { navigate("/care/manager/stats"); }} class="active">통계관리</button>
                    <button onClick={() => { navigate("/care/manager/product"); }}>건기식관리</button>
                    <button onClick={() => { navigate("/care/manager/program"); }}>프로그램관리</button>
                    <button onClick={() => { navigate("/care/manager/symptom"); }}>병증관리</button>
                </div>
                <div class="right_area">
                    <button onClick={() => signOut(navigate, auth) }>Logout</button>
                </div>
            </div>

            <div id="wrap">
                <div class="top">
                    <div class="tit">통계</div>
                    <div class="r_area">
                        <select name="" id="" onChange={handleFilterChange} value={selectedFilter}>
                            <option value="all">전체</option>
                            <option value="age">나이</option>
                            <option value="gender">성별</option>
                        </select>
                        <input
                            type="text"
                            name="search"
                            value={searchValue}
                            onChange={handleSearchInputChange}
                            id="search"
                            placeholder="검색어를 입력하세요."
                        />
                        <a href="#" onClick={handleSearch}>검색</a> |

                        <select name="" id="" onChange={handleOptionChange} value={selectedOption}>
                            <option value="all">::선택::</option>
                            <option value="phoneTransferYn"> 휴대폰전송고객</option>
                            <option value="detailCheckYn"> 상세진단고객</option>
                        </select>

                    </div>
                </div>
                <section>
                    <p class="sub_text"> 목록</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="list">
                        <thead>
                            <tr>
                                <td>No.</td>
                                <td>등록일</td>
                                <td>나이</td>
                                <td>성별</td>
                                <td>간편진단</td>
                                <td>상세진단</td>
                                <td>휴대폰전송</td>
                                <td>휴대폰번호</td>
                                <td>상세보기</td>
                            </tr>
                        </thead>
                        <tbody>
                        {users.map((item, index) => (
                            <tr key={index}>
                                <td>{totalSize - ((currentPage-1) * itemsPerPage) - index}</td>
                                <td>{formatDate(item.createDate)}</td>
                                <td>{item.age}</td>
                                <td>{item.gender}</td>
                                <td>{item.simpleCheckYn ? "작성완료" : "미작성"}</td>
                                <td>{item.detailCheckYn ? "작성완료" : "미작성"}</td>
                                <td>{item.phoneTransferYn ? "전송완료" : "미전송"}</td>
                                <td>{item.phoneNumber}</td>
                                <td>
                                    <button onClick={() => { navigate(`/care/manager/stats/${item.id}`); }}>
                                        상세소개
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className="number">
                        <div className="number">
                            <button disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>◀</button>
                            {renderPageButtons()}
                            <button disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>▶</button>
                        </div>
                    </div>
                </section>
            </div>



            <div class="footer">
                <p>© SLOW LIFE TOGETHER</p>
            </div>


        </div>
    );
}

export default AdminStats;
