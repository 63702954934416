import React from 'react';
// import '../../css/HealthDetailCheckResult.css'; 
import { HealthDetailResultProduct } from '.';

export const HealthDetailResultProductSection = ({product}) => {
    return (
          <div>
            <div className='flex flex-col items-center text-center'>
                <div className="text-4xl font-jalnan text-sc-green sm:text-3xl md:text-6xl ki:text-[10vw] leading-none"> 건강기능식품 추천</div>
                <div className="mt-3 text-sm font-jalnan text-sc-black md:mt-7 ki:mt-[3.5vw] md:text-2xl ki:text-[3.5vw] leading-none">내 건강에 꼭 필요한 채움</div>
                <div className="w-full mt-5 h-[1px] bg-sc-green md:h-1 md:mt-10 ki:h-[0.5vw] ki:mt-[3.5vw]" />
                <HealthDetailResultProduct product={product}/>
            </div>
        </div>
    );
};
