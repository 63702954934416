import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom'; // React Router v6 사용시
import './css/common.css';
import logoImage from './img/top_logo.png';
import axios from "axios";
import {baseUrl} from "../../base";
import {signOut} from "./signout";
import {useAuth} from "../context";

const AdminProgramWrite = () => {
    const { programId } = useParams();
    const [form, setForm] = useState({
        name: '',
        description: '',
        url: '',
        file: null,
    });
    const auth = useAuth();
    useEffect(() => {
        axios.post(`${baseUrl}/v1/verify/access-token`)
            .then(res => {
                if(res.data) {
                    auth.login(res.data);
                }
            })
            .catch(err => {
                console.log(err);
                navigate("/care/manager");
            })
    },[]);

    const navigate = useNavigate(); // React Router v6 사용시

    useEffect(() => {
        axios.get(`${baseUrl}/v1/programs/${programId}`)
            .then(response => {
                const { data } = response;
                setForm({
                    name: data.name,
                    description: data.description,
                    url: data.url,
                });
            })
            .catch(error => {
                console.error('There was a problem fetching program data:', error);
            });
    }, []);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setForm((prev) => ({
            ...prev,
            [id]: value
        }))
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setForm((prev) => ({
            ...prev,
            file: file, // 파일 추가
        }));
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('name', form.name);
        formData.append('description', form.description);
        formData.append('file', form.file);

        try {
            await axios.put(`${baseUrl}/v1/programs/${programId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            navigate('/care/manager/program');
        } catch (error) {
            console.error('There was a problem with the axios request:', error);
            navigate('/care/manager/program');
        }
    }

    const handleDelete = () => {
        const confirmDelete = window.confirm('삭제하시겠습니까?'); // 알림 창 띄우기

        if (confirmDelete) {
            axios.delete(`${baseUrl}/v1/programs/${programId}`)
                .then(response => {
                    navigate("/care/manager/program");
                })
                .catch(error => {
                    console.error('프로그램 삭제 중 문제가 발생했습니다:', error);
                });
        }
    }

    return (
        <div>
            <div id="top_area">
                <div class="top_logo"><img src={logoImage} alt="top logo" /></div>
                <div class="topmenu_all">
                    <button onClick={() => { navigate("/care/manager/stats"); }}>통계관리</button>
                    <button onClick={() => { navigate("/care/manager/product"); }}>건기식관리</button>
                    <button onClick={() => { navigate("/care/manager/program"); }} class="active">프로그램관리</button>
                    <button onClick={() => { navigate("/care/manager/symptom"); }}>병증관리</button>
                </div>
                <div class="right_area">
                    <button onClick={() => signOut(navigate, auth)}>Logout</button>
                </div>
            </div>

            <div id="wrap">
                <div class="top">
                    <div class="tit">프로그램 정보</div>
                    <div class="r_area">
                        <button class="btn_point" onClick={handleSubmit}>저장하기</button>
                        <button onClick={handleDelete}>삭제</button>
                        <button onClick={() => { navigate("/care/manager/program"); }}>목록</button>
                    </div>
                </div>
                <section>
                    <p class="sub_text"> 기본정보</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >
                        <tr>
                            <td>프로그램명</td>
                            <td><input id={'name'} type="text" value={form.name} onChange={handleChange} /></td>
                        </tr>
                        <tr>
                            <td>기본설명</td>
                            <td><input id={'description'} type="text" value={form.description} onChange={handleChange} /></td>
                        </tr>
                        <tr>
                            <td>동영상파일</td>
                            <td>
                                <input type="file" id="fileInput" onChange={handleFileChange} />
                                <label>   {baseUrl}/{form.url}</label>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </section>

            </div>


            <div class="footer">
                <p>© SLOW LIFE TOGETHER</p>
            </div>
        </div>
    );
}

export default AdminProgramWrite;
