import React, { useState } from 'react';
import '../../../css/insertInformation.css';

export const Component1 = ({ onNextStep }) => {

    const [clickable, setClickable] = useState(true);

    const handleAgeSelect = (age, event) => {
        if (!clickable) return;

        setClickable(false);
        const button = event.target;
        button.classList.add('button-clicked');

        setTimeout(() => {
            onNextStep(2, 'age', age);
            button.classList.remove('button-clicked');
            setClickable(true);
        }, 1000);
    };

    return (
        <div className='flex flex-col'>
            <label
                className='pt-5 text-5xl text-center font-jalnan pb-10 text-white sm:text-4xl md:pt-0 md:text-[11vw] md:pb-10 ki:pt-[6vw] ki:text-[11vw] ki:pb-[10vw]'>
                내 나이는?
            </label>
            <div
                className='flex items-center justify-between mb-5 ml-5 mr-5 md:mb-10 md:mr-20 md:ml-20 ki:mb-[3vw] ki:mr-[6vw] ki:ml-[6vw]'>
                <button
                    className='outline-none outline-none cursor-none flex-grow p-3 mr-3 text-2xl text-white border-[1px] basis-0 font-jalnan rounded-xl sm:text-xl md:border-2 md:rounded-2xl md:text-[5vw] md:h-[14vw] md:mr-10 ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:h-[14vw] ki:mr-[3vw]'
                     onClick={(e) => handleAgeSelect('101', e)}>
                    10대
                </button>
                <button
                    className='outline-none cursor-none flex-grow p-3 text-2xl text-white border-[1px] basis-0 font-jalnan rounded-xl sm:text-xl md:text-[5vw] md:h-[14vw] md:border-2 md:rounded-2xl ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:h-[14vw]' 
                    onClick={(e) => handleAgeSelect('102', e)}>
                    20대
                </button>
            </div>
            <div
                className='flex items-center justify-between mb-5 ml-5 mr-5 md:mb-10 md:mr-20 md:ml-20 ki:mb-[3vw] ki:mr-[6vw] ki:ml-[6vw]'>
                <button
                    className='outline-none cursor-none flex-grow p-3 mr-3 text-2xl text-white border-[1px] basis-0 font-jalnan rounded-xl sm:text-xl md:border-2 md:rounded-2xl md:text-[5vw] md:h-[14vw] md:mr-10 ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:h-[14vw] ki:mr-[3vw]' 
                    onClick={(e) => handleAgeSelect('103', e)}>
                    30대
                </button>
                <button
                    className='outline-none cursor-none flex-grow p-3 text-2xl text-white border-[1px] basis-0 font-jalnan rounded-xl sm:text-xl md:text-[5vw] md:h-[14vw] md:border-2 md:rounded-2xl ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:h-[14vw]'
                    onClick={(e) => handleAgeSelect('104', e)}>
                    40대
                </button>
            </div>
            <div
                className='flex items-center justify-between mb-5 ml-5 mr-5 md:mb-10 md:mr-20 md:ml-20 ki:mb-[3vw] ki:mr-[6vw] ki:ml-[6vw]'>
                <button
                    className='outline-none cursor-none flex-grow p-3 mr-3 text-2xl text-white border-[1px] basis-0 font-jalnan rounded-xl sm:text-xl md:border-2 md:rounded-2xl md:text-[5vw] md:h-[14vw] md:mr-10 ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:h-[14vw] ki:mr-[3vw]'
                     onClick={(e) => handleAgeSelect('105', e)}>
                    50대
                </button>
                <button
                    className='outline-none cursor-none flex-grow p-3 text-2xl text-white border-[1px] basis-0 font-jalnan rounded-xl sm:text-xl md:text-[5vw] md:h-[14vw] md:border-2 md:rounded-2xl ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:h-[14vw]' 
                    onClick={(e) => handleAgeSelect('106', e)}>
                    60대
                </button>
            </div>
            <div
                className='flex items-center justify-between mb-5 ml-5 mr-5 md:mb-10 md:mr-20 md:ml-20 ki:mb-[3vw] ki:mr-[6vw] ki:ml-[6vw]'>
                <button
                    className='outline-none cursor-none flex-grow p-3 mr-3 text-2xl text-white border-[1px] basis-0 font-jalnan rounded-xl sm:text-xl md:border-2 md:rounded-2xl md:text-[5vw] md:h-[14vw] md:mr-10 ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:h-[14vw] ki:mr-[3vw]' 
                    onClick={(e) => handleAgeSelect('107', e)}>
                    70대
                </button>
                <button
                    className='outline-none cursor-none flex-grow p-3 text-2xl text-white border-[1px] basis-0 font-jalnan rounded-xl sm:text-xl md:text-[5vw] md:h-[14vw] md:border-2 md:rounded-2xl ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:h-[14vw]'
                     onClick={(e) => handleAgeSelect('108', e)}>
                    80대이상
                </button>
            </div>
        </div>
    );
}