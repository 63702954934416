import React from 'react';
import image from '../../../assets/img_product_01.png'
import buttonContainer from '../../../assets/btn_bg_01.png'
import { baseUrl } from "../../../base";

export const MobileProduct = ({ product }) => {

    if (!product || !product.url || !product.link) {
        return <div>프로그램 정보를 찾을 수 없습니다.</div>;
    }

    const handleBuyClick = () => {
        window.open(product.link);
    };

    const formattedKeywords = product.keyword.replace(/,/g, ' #');

    return (
        <div>
            <div className='flex flex-col items-center pt-3 pb-3 md:pt-6 ki:pt-[2.5vw]'>
                <img src={`${baseUrl}/${product.url}`} className='w-[80vw] h-auto' alt="test" />
                <label className='mt-3 text-xl leading-tight text-sc-black font-jalnan md:text-5xl ki:text-[5vw]'>
                    {product.name}
                </label>
                <label
                    className='text-sm leading-tight text-center text-sc-gray font-jalnan md:text-3xl md:mt-5 ki:text-[3.5vw] ki:mt-[1vw] break-words'
                >
                    {product.description}
                </label>
                <label
                    className='text-sm leading-tight text-center text-sc-blue font-jalnan md:text-3xl md:mt-5 ki:text-[3.5vw] ki:mt-[1vw] break-words'
                >
                    #{formattedKeywords}
                </label>
                <div className='relative mt-6 md:mt-10'>
                    <img src={buttonContainer} alt='start button' className='w-[45vw] mx-auto' />
                    <p
                        onClick={handleBuyClick}
                        className='w-[45vw] absolute text-[7vw] text-center text-white -translate-x-1/2 -translate-y-1/2 font-jalnan top-1/2 left-1/2 md:text-[7.5vw] md:leading-none'>
                        구매하기
                    </p>
                </div>
            </div>
        </div>
    );
};