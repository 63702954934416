import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/HealthFunctionFood.css'
import TimeoutRedirect from './TimeoutRedirect';
import healthFunctionFood from '../assets/healthFunctionFood.png'

export const HealthFunctionalFood = () => {

    const navigate = useNavigate();

    const moveHome = () => {
        navigate('/care')
    }

    return (
        <TimeoutRedirect redirectPath="/care">
            <main className='flex flex-col w-screen h-screen bg-sc-blue'>
                <div className='flex flex-col justify-between w-full h-16 md:h-[17vw] ki:h-[17vw]'>
                    <div className='flex justify-between items-center w-full h-16 pl-5 pr-5 md:pr-10 md:pl-10 md:h-[17vw] ki:pr-[3.5vw] ki:pl-[3.5vw] ki:h-[17vw]'>
                        <div className="text-[6vw] text-white font-jalnan">건강기능식품</div>
                        <div>
                            <button className='outline-none cursor-none text-lg w-28 text-white rounded-full font-jalnan border-[1px] px-4 py-1 border-white sm:text-base md:border-2 md:w-[30vw] md:text-[4vw] md:h-[10vw] ki:border-4 ki:w-[30vw] ki:text-[4vw] ki:h-[10vw]' onClick={moveHome}>돌아가기</button>
                        </div>
                    </div>
                </div>
                <div className='m-5 mt-0 md:m-10 md:mt-0 ki:m-[3.5vw] ki:mt-0 overflow-y-auto rounded-[4vw]'>
                    <img
                        src={healthFunctionFood}
                        alt='healthFunctionFood'
                    />
                </div>
            </main>
        </TimeoutRedirect>
    );
}