import {Outlet, Route, Routes} from 'react-router-dom';
import {useAuth} from './pages';
import React from 'react';
import AdminHome from './pages/manager/AdminHome';
import AdminProduct from './pages/manager/AdminProduct';
import AdminProductEdit from './pages/manager/AdminProductEdit';
import AdminProductWrite from './pages/manager/AdminProductWrite';
import AdminProgram from './pages/manager/AdminProgram';
import AdminProgramEdit from './pages/manager/AdminProgramEdit';
import AdminProgramWrite from './pages/manager/AdminProgramWrite';
import AdminStats from './pages/manager/AdminStats';
import AdminStatsWrite from './pages/manager/AdminStatsWrite';
import AdminSymptom from './pages/manager/AdminSymptom';
import AdminSymptomEdit from './pages/manager/AdminSymptomEdit';

const PrivateRoutes = () => {
    const auth = useAuth();

    console.log('private routes', auth)

    return <Outlet/>
}

const Admin = () => {
    return (
        <Routes element={<PrivateRoutes/>}>
            <Route path="/care/manager/" element={<AdminHome/>}/>
            <Route path="/care/manager/product" element={<AdminProduct/>}/>
            <Route
                path="/care/manager/product/write"
                element={<AdminProductWrite/>}
            />
            <Route
                path="/care/manager/product/:productId/edit"
                element={<AdminProductEdit/>}
            />

            <Route path="/care/manager/program" element={<AdminProgram/>}/>
            <Route
                path="/care/manager/program/write"
                element={<AdminProgramWrite/>}
            />
            <Route
                path="/care/manager/program/:programId/edit"
                element={<AdminProgramEdit/>}
            />

            <Route path="/care/manager/stats" element={<AdminStats/>}/>
            <Route path="/care/manager/stats/:userId" element={<AdminStatsWrite/>}/>

            <Route path="/care/manager/symptom" element={<AdminSymptom/>}/>
            <Route
                path="/care/manager/symptom/:symptomId/edit"
                element={<AdminSymptomEdit/>}
            />
        </Routes>
    );
};

export default Admin;
