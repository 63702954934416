import React from 'react';
// import '../../css/HealthDetailCheckResult.css'; 

export const HealthDetailResultSymptomSection = ({ scoreValues, descriptions }) => {

  return (
    <div>
      <div className='flex flex-col items-center text-center'>
        <div className="text-4xl font-jalnan text-sc-green sm:text-3xl md:text-6xl ki:text-[10vw] leading-none">
          건강진단
        </div>
        <div className="mt-2 text-xs font-jalnan text-sc-black md:mt-7 ki:mt-[3.5vw] md:text-2xl ki:text-[3.5vw] leading-none">
          건강한 나를 위한 맞춤 분석
        </div>
        <div className="w-full mt-5 h-[1px] bg-sc-green md:h-1 md:mt-10 ki:h-[0.5vw] ki:mt-[3.5vw]" />
        <label
          className='text-sm leading-tight text-center text-sc-green mt-3 pt-3 md:pt-6 ki:pt-[2.5vw] font-jalnan md:text-2xl md:mt-5 ki:text-[3vw] ki:mt-[3.5vw] break-words'
        >
          #{scoreValues[0].keyword} #{scoreValues[1].keyword}
        </label>
        <label className='text-sm leading-tight text-center text-sc-gray mt-3 font-jalnan md:text-2xl md:mt-5 ki:text-[3vw] ki:mt-[3.5vw] break-words'>
          {descriptions[0]}
        </label>
        <label className='text-sm leading-tight text-center text-sc-gray mt-3 font-jalnan md:text-2xl md:mt-5 ki:text-[3vw] ki:mt-[3.5vw] break-words'>
          {descriptions[1]}
        </label>
      </div>
    </div>
  );
};
