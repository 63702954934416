import React from 'react';
// import '../../css/HealthDetailCheckResult.css';
import { baseUrl } from "../../../base";

export const HealthResultProduct = ({ product }) => {

    const formattedKeywords = product.keyword.replace(/,/g, ' #');

    return (
        <div>
            <div className='flex flex-col items-center pt-3 pb-3 md:pt-6 ki:pt-[2.5vw]'>
                <img src={`${baseUrl}/${product.url}`} className='w-[80vw] h-auto' alt="test" />
                <label className='mt-3 text-xl leading-tight text-sc-black font-jalnan md:text-5xl ki:text-[5vw]'>
                    {product.name}
                </label>
                <label
                    className='text-sm leading-tight text-center text-sc-gray font-jalnan md:text-3xl md:mt-5 ki:text-[3.5vw] ki:mt-[1vw] break-words'
                >
                    {product.description}
                </label>
                <label
                    className='text-sm leading-tight text-center text-sc-blue font-jalnan md:text-3xl md:mt-5 ki:text-[3.5vw] ki:mt-[1vw] break-words'
                >
                    #{formattedKeywords}
                </label>
            </div>
        </div>
    );
};