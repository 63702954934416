import React, { useState } from 'react';
import image from "../../../assets/simple_01.png"
import '../../../css/insertInformation.css';

export const Component4 = ({ onNextStep }) => {

    const [clickable, setClickable] = useState(true);

    const handleAgeSelect = (pee, event) => {
        if (!clickable) return;

        setClickable(false);
        const button = event.target;
        button.classList.add('button-clicked');

        setTimeout(() => {
            onNextStep(5, 'pee', pee);
            button.classList.remove('button-clicked');
            setClickable(true);
        }, 1000);
    };

    return (
        <div className='flex flex-col'>
            <label
                className='pt-5 text-5xl text-center font-jalnan pb-10 text-white sm:text-4xl md:pt-0 md:text-[11vw] md:pb-10 ki:pt-[6vw] ki:text-[11vw] ki:pb-[10vw]'>
                소변상태
            </label>
            <div
                className='relative mx-5 mb-5 sm:mr-2 sm:ml-2 md:mb-10 md:mr-20 md:ml-20 ki:mb-10 ki:mr-20 ki:ml-20'>
                <img
                    src={image}
                    alt="test"
                    className='w-full h-auto' />
                <p
                    className='absolute w-full sm:text-xs text-lg text-center text-sc-blue -translate-x-1/2 -translate-y-8 font-jalnan left-1/2 md:-translate-y-16 md:text-[4vw] ki:-translate-y-20 ki:text-[4vw]'>
                    거품이 많이 있는 소변
                </p>
            </div>

            <div
                className='flex items-center justify-between mb-5 ml-5 mr-5 sm:mr-2 sm:ml-2 md:mb-10 md:mr-20 md:ml-20 ki:mb-[3vw] ki:mr-[6vw] ki:ml-[6vw]'>
                <button
                    className='outline-none cursor-none flex-grow p-3 mr-3 text-xl text-white border-[1px] basis-0 font-jalnan rounded-xl sm:mr-1 sm:text-base md:border-2 md:rounded-2xl md:text-[5vw] md:h-[14vw] md:mr-10 ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:h-[14vw] ki:mr-[3vw]' onClick={(e) => handleAgeSelect('401', e)}>
                    거품이 많다
                </button>
                <button
                    className='outline-none cursor-none flex-grow p-3 text-xl text-white border-[1px] basis-0 font-jalnan rounded-xl sm:text-base md:border-2 md:rounded-2xl md:text-[5vw] md:h-[14vw] ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:h-[14vw]' onClick={(e) => handleAgeSelect('402', e)}>
                    갈색이다
                </button>
            </div>
            <div
                className='flex items-center justify-between mb-5 ml-5 mr-5 sm:mr-2 sm:ml-2 md:mb-10 md:mr-20 md:ml-20 ki:mb-[3vw] ki:mr-[6vw] ki:ml-[6vw]'>
                <button
                    className='outline-none cursor-none flex-grow p-3 mr-3 text-xl text-white border-[1px] basis-0 font-jalnan rounded-xl sm:mr-1 sm:text-base md:border-2 md:rounded-2xl md:text-[5vw] md:h-[14vw] md:mr-10 ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:h-[14vw] ki:mr-[3vw]' onClick={(e) => handleAgeSelect('403', e)}>
                    오래 나온다
                </button>
                <button
                    className='outline-none cursor-none flex-grow p-3 text-xl text-white border-[1px] basis-0 font-jalnan rounded-xl sm:text-base md:border-2 md:rounded-2xl md:text-[5vw] md:h-[14vw] ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:h-[14vw]' onClick={(e) => handleAgeSelect('404', e)}>
                    해당사항 없음
                </button>
            </div>
        </div >
    );

}