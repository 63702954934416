import React from 'react';
// import '../../css/Navigation.css'; 

const Navigation = ({ onPrev, onNext }) => {
    return (
      <nav className='fixed bottom-0 z-50 flex justify-between w-full h-20 text-2xl font-jalnan md:text-[6vw] md:h-[20vw] ki:h-[20vw] ki:text-[6vw]'>
          <button className='outline-none cursor-none flex-1 text-white bg-black w-[25%] h-full' onClick={ onPrev }>
              이전
          </button>
          <button className='outline-none cursor-none flex-3 text-white bg-sc-green w-[75%] h-full' onClick={ onNext }>  
              확인
          </button>
      </nav>
    );
  };
  
  export default Navigation;