import React, { useState } from 'react';
import image from "../../../assets/simple_02.png";
import '../../../css/insertInformation.css';
import Webcam from 'react-webcam';

export const Component6 = ({ onNextStep }) => {

    const [clickable, setClickable] = useState(true);
    const [webcam, setWebcam] = useState(false);
    const [checkMobile, setCheckMobile] = useState(false);

    const handleAgeSelect = (tongue, event) => {
        if (!clickable) return;

        setClickable(false);
        const button = event.target;
        button.classList.add('button-clicked');

        setTimeout(() => {
            onNextStep(7, 'tongue', tongue);
            button.classList.remove('button-clicked');
            setClickable(true);
        }, 1000);
    };

    const openCamera = () => {
        const interfaceName = 'Android'
        const isWebViewInApp =
            window[interfaceName] ||
            (window.webkit &&
                window.webkit.messageHandlers &&
                window.webkit.messageHandlers[interfaceName]);
        if (isWebViewInApp) {
            const stringifiedMessage = "text"
            // Android
            window[interfaceName] && window[interfaceName].startCamera(stringifiedMessage);
            // iOS
            window.webkit &&
                window.webkit.messageHandlers &&
                window.webkit.messageHandlers[interfaceName] &&
                window.webkit.messageHandlers[interfaceName].postMessage(
                    stringifiedMessage
                );
            setCheckMobile(true);
        } else {
            setCheckMobile(false);
            setWebcam(true);
        }
    }

    const closedCamera = () => {
        if (checkMobile) { return }
        setWebcam(false);
    }

    const screenWidth = window.innerWidth; // Get the width of the screen

    const videoConstraints = {
        width: screenWidth * 86 / 100,
        height: screenWidth * 86 / 100 * 738 / 1356,
        facingMode: "user"
    };

    return (
        <div className='flex flex-col'>
            <label
                className='pt-5 text-5xl text-center font-jalnan pb-10 text-white sm:text-4xl md:pt-0 md:text-[11vw] md:pb-10 ki:pt-[6vw] ki:text-[11vw] ki:pb-[10vw]'>
                혀상태
            </label>
            {webcam ? ( // Conditionally render webcam component
                <div className=' relative flex flex-col items-center justify-center h-auto mb-[3vw] mx-[7vw]'>
                    <Webcam
                        className='rounded-[3vw]'
                        audio={false}
                        height={screenWidth * 86 / 100 * 738 / 1356}
                        width={screenWidth * 86 / 100}
                        videoConstraints={videoConstraints}
                    />
                    <button
                        className=' absolute right-[5vw] top-[3vw] text-[5vw]'
                        onClick={() => closedCamera()}
                    >
                        X
                    </button>
                </div>
            ) : (
                <div className='flex flex-col items-center justify-center h-auto mb-[3vw] mx-[7vw]'>
                    <img
                        src={image}
                        alt="test"
                        className='w-full h-auto' />

                    <div
                        className='absolute z-50 flex flex-col w-full items-center text-center -translate-x-1/2 -translate-y-4 font-jalnan left-1/2 sm:-translate-y-2 ki:-translate-y-[3vw]'>
                        <div
                            className='w-full leading-tight text-sc-blue sm:text-xs text-lg md:text-[4vw]  ki:text-[4vw]'>
                            치흔 : 혀 둘레 치아 자국
                        </div>
                        <div
                            className='text-black leading-tight text-xs md:text-[4vw] ki:text-[4vw]'>
                            카메라를 실행해서 내 혓바닥을 확인하세요.
                        </div>
                        <button
                            className='outline-none cursor-none text-lg w-29 bg-sc-blue text-white rounded-full m-3 font-jalnan border-[1px] px-4 py-1 border-white sm:text-base md:m-10 md:border-2 md:w-[30vw] md:text-[4vw] md:h-[10vw] ki:mt-[7vw] ki:border-4 ki:w-[30vw] ki:text-[4vw] ki:h-[10vw]'
                            onClick={openCamera}>
                            카메라실행
                        </button>
                    </div>
                </div>
            )}

            <div
                className='flex items-center justify-between mb-[3vw] mx-[7vw] sm:mx-[4vw]'>
                <button
                    className='outline-none cursor-none flex-grow p-3 mr-3 text-xl text-white border-[1px] basis-0 font-jalnan rounded-xl sm:mr-1 sm:text-base md:border-2 md:rounded-2xl md:text-[5vw] md:h-[14vw] md:mr-10 ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:h-[14vw] ki:mr-[3vw]' onClick={(e) => handleAgeSelect('601', e)}>
                    치흔이 있다
                </button>
                <button
                    className='outline-none cursor-none flex-grow p-3 text-xl text-white border-[1px] basis-0 font-jalnan rounded-xl sm:text-base md:border-2 md:rounded-2xl md:text-[5vw] md:h-[14vw] ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:h-[14vw]' onClick={(e) => handleAgeSelect('602', e)}>
                    누렇다
                </button>
            </div>
            <div
                className='flex items-center justify-between mb-[3vw] mx-[7vw] sm:mx-[4vw]'>
                <button
                    className='outline-none cursor-none flex-grow p-3 mr-3 text-xl text-white border-[1px] basis-0 font-jalnan rounded-xl sm:mr-1 sm:text-base md:border-2 md:rounded-2xl md:text-[5vw] md:h-[14vw] md:mr-10 ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:h-[14vw] ki:mr-[3vw]' onClick={(e) => handleAgeSelect('603', e)}>
                    혓바늘이 있다
                </button>
                <button
                    className='outline-none cursor-none flex-grow p-3 text-xl text-white border-[1px] basis-0 font-jalnan rounded-xl sm:text-base md:border-2 md:rounded-2xl md:text-[5vw] md:h-[14vw] ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:h-[14vw]' onClick={(e) => handleAgeSelect('604', e)}>
                    해당사항 없음
                </button>
            </div>
        </div>
    );

}