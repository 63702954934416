import React from 'react';
import { useNavigate } from 'react-router-dom';

export const HealthCheckResultTopNavigationBar = () => {

    const navigate = useNavigate(); 

    const moveHome = () => {
        navigate('/care')
    }
    
    return (
        <div className='fixed z-50 flex justify-end items-center bg-white w-full h-16 pl-5 pr-5 md:pr-10 md:pl-10 md:h-36 ki:pr-[3.5vw] ki:pl-[3.5vw] ki:h-[17vw]'>
            <div>
            <button className='outline-none cursor-none text-lg w-28 text-sc-blue rounded-full font-jalnan border-[1px] px-4 py-1 border-sc-blue sm:text-base md:border-2 md:w-[30vw] md:text-[4vw] md:h-[10vw] ki:border-4 ki:w-[30vw] ki:text-[4vw] ki:h-[10vw]' onClick={ moveHome }>다시하기</button>
            </div>
        </div>
    );
};