export const HealthDetailQuestionList = {

    sectionData1: [
        {
            sectionId: 1,
            title: "과거력, 현병력",
            questions: [
                {
                    id: 2,
                    content: "가족 중에 다음 질환을 앓았던 사람이 있나요?",
                    type: 'checkbox',
                    options: [
                        {
                            label: "고혈압", value: "201" },
                        { label: "고지혈증", value: "202" },
                        { label: "당뇨", value: "203" },
                        { label: "심장질환", value: "204" },
                        { label: "간질환", value: "205" },
                        { label: "암", value: "206" }
                    ]
                },
                {
                    id: 3,
                    content: "다음과 같은 진단을 받았거나, 현재 약물 치료 중인가요?",
                    type: 'checkbox',
                    options: [
                        { label: "고혈압, 고지혈증", value: "301" },
                        { label: "당뇨", value: "302" },
                        { label: "심혈관 질환 (협심증, 심근경색, 부정맥 등)", value: "303" },
                        { label: "뇌혈관 질환 (뇌경색, 뇌출혈 등)", value: "304" },
                        { label: "간질환 (간염, 간경화, 지방간 등)", value: "305" },
                        { label: "소화기질환 (역류성식도염, 위염, 크론병, 과민성대장증후군, 등)", value: "306" },
                        { label: "폐질환 (결핵, 기흉, 폐렴 등)", value: "307" },
                        { label: "근골격계 질환 (디스크, 척추관협착증, 각종 관절통 등)", value: "308" },
                        { label: "기타 자가면역질환 (류마티스, 베체트 등)", value: "309" },
                        { label: "각종 암", value: "310" },
                        { label: "없음", value: "311" }
                    ]
                }
            ]
        }
    ],

    sectionData2: [
        {
            sectionId: 2,
            title: "식습관",
            questions: [
                {
                    id: 4,
                    content: "식사시간이 규칙적인가요?",
                    type: 'radio',
                    options: [
                        { label: "매우 규칙적 (주 6-7일)", value: "401" },
                        { label: "규칙적인 편 (주 4-5일)", value: "402" },
                        { label: "불규칙한 편 (주 2-3일)", value: "403" },
                        { label: "매우 불규칙적", value: "404" }
                    ]
                },
                {
                    id: 5,
                    content: "어떤 밥을 좋아하나요?",
                    type: 'radio',
                    options: [
                        { label: "진밥", value: "501" },
                        { label: "고들밥", value: "502" }
                    ]
                },
                {
                    id: 6,
                    content: "한 끼 식사 시 소요되는 시간은 어느 정도인가요?",
                    type: 'radio',
                    options: [
                        { label: "30분 이상", value: "601" },
                        { label: "20 ~ 30분", value: "602" },
                        { label: "10 ~20분", value: "603" },
                        { label: "10분 이내", value: "604" }
                    ]
                },
                {
                    id: 7,
                    content: "식사할 때 몇 번 정도 씹나요?",
                    type: 'radio',
                    options: [
                        { label: "40회 이상", value: "701" },
                        { label: "30~40회", value: "702" },
                        { label: "20~30회", value: "703" },
                        { label: "10 ~ 20회", value: "704" },
                        { label: "10회 미만", value: "705" }
                    ]
                },
                {
                    id: 8,
                    content: "식사량은 어떤가요?",
                    type: 'radio',
                    options: [
                        { label: "소식하는 편", value: "801" },
                        { label: "적당히 먹는 편", value: "802" },
                        { label: "많이 먹는 편", value: "803" }
                    ]
                },
                {
                    id: 9,
                    content: "야식을 얼마나 자주 먹나요?",
                    type: 'radio',
                    options: [
                        { label: "없었음", value: "901" },
                        { label: "한달 1~2회이하", value: "902" },
                        { label: "주 1~2회", value: "903" },
                        { label: "주 3 ~4회", value: "904" },
                        { label: "주 5회 이상", value: "905" }
                    ]
                },
                {
                    id: 10,
                    content: "밀가루음식(면류)을 얼마나 자주 먹나요?",
                    type: 'radio',
                    options: [
                        { label: "없었음", value: "1001" },
                        { label: "한달 1~2회이하", value: "1002" },
                        { label: "주 1~2회", value: "1003" },
                        { label: "주 3 ~4회", value: "1004" },
                        { label: "주 5회 이상", value: "1005" }
                    ]
                },
                {
                    id: 11,
                    content: "육류를 얼마나 자주 먹나요?",
                    type: 'radio',
                    options: [
                        { label: "없었음", value: "1101" },
                        { label: "한달 1~2회이하", value: "1102" },
                        { label: "주 1~2회", value: "1103" },
                        { label: "주 3 ~4회", value: "1104" },
                        { label: "주 5회 이상", value: "1105" }
                    ]
                },
                {
                    id: 12,
                    content: "인스턴트식품을 얼마나 자주 먹나요?",
                    type: 'radio',
                    options: [
                        { label: "없었음", value: "1201" },
                        { label: "한달 1~2회이하", value: "1202" },
                        { label: "주 1~2회", value: "1203" },
                        { label: "주 3 ~4회", value: "1204" },
                        { label: "주 5회 이상", value: "1205" }
                    ]
                },
                {
                    id: 13,
                    content: "최근 1년 간 술을 얼마나 자주 마시나요?",
                    type: 'radio',
                    options: [
                        { label: "안 함", value: "1301" },
                        { label: "한달 1~2회이하", value: "1302" },
                        { label: "주 1-2회", value: "1303" },
                        { label: "주 3-4회", value: "1304" },
                        { label: "주 5회 이상", value: "1305" }
                    ]
                },
                {
                    id: 14,
                    content: "현재 담배를 얼마나 자주 피나요?",
                    type: 'radio',
                    options: [
                        { label: "안 함", value: "1401" },
                        { label: "주 1-2회", value: "1402" },
                        { label: "주 3-4회", value: "1403" },
                        { label: "주 5회 이상", value: "1404" }
                    ]
                },
            ]
        },

        {
            sectionId: 3,
            title: "기분 상태",
            questions: [
                {
                    id: 15,
                    content: "요즘 기분은 어떤가요?",
                    type: 'checkbox',
                    options: [
                        { label: "기분이 좋고 근심이 없다", value: "1501" },
                        { label: "화가 나고 짜증이 난다", value: "1502" },
                        { label: "생각이 많고 우울하다", value: "1503" },
                        { label: "쉽게 불안하고 두렵다", value: "1504" }
                    ]
                },
            ]
        },

        {
            sectionId: 4,
            title: "노동",
            questions: [
                {
                    id: 16,
                    content: "몸을 움직이는 일이 많나요?",
                    type: 'radio',
                    options: [
                        { label: "적은 편", value: "1601" },
                        { label: "많은 편", value: "1602" }
                    ]
                },
                {
                    id: 17,
                    content: "이런저런 신경 쓸 일이 많나요?",
                    type: 'radio',
                    options: [
                        { label: "적은 편", value: "1701" },
                        { label: "많은 편", value: "1702" }
                    ]
                },
            ]
        },

        {
            sectionId: 5,
            title: "외인",
            questions: [
                {
                    id: 18,
                    content: "감기에 자주 걸리나요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "1801" },
                        { label: "아니요", value: "1802" }
                    ]
                },
                {
                    id: 19,
                    content: "추위/더위를 많이 타나요?",
                    type: 'radio',
                    options: [
                        { label: "추위를 타는 편", value: "1901" },
                        { label: "더위를 타는 편", value: "1902" },
                        { label: "추위, 더위 모두 타는 편", value: "1903" },
                        { label: "추위, 더위 모두 안 타는 편", value: "1904" }
                    ]
                },
            ]
        },
    ],

    sectionData3: [
        {
            sectionId: 6,
            title: "소화",
            questions: [
                {
                    id: 20,
                    content: "자주 소화가 안되나요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "2001" },
                        { label: "아니요", value: "2002" }
                    ]
                },
                {
                    id: 21,
                    content: "자주 체하는 편인가요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "2101" },
                        { label: "아니요", value: "2102" }
                    ]
                },
                {
                    id: 22,
                    content: "어떤 음식을 먹으면 소화가 더 잘 안되나요?",
                    type: 'checkbox',
                    options: [
                        { label: "고기류", value: "2201" },
                        { label: "기름진 음식", value: "2202" },
                        { label: "밀가루 음식(면, 빵 등)", value: "2203" },
                        { label: "잡곡류", value: "2204" },
                        { label: "기타", value: "2205" }
                    ]
                },
                {
                    id: 23,
                    content: "평소 자주 아래와 같은 소화기 증상이 있나요?",
                    type: 'checkbox',
                    options: [
                        { label: "명치부위가 답답하거나 아프다", value: "2301" },
                        { label: "속이 쓰리다", value: "2302" },
                        { label: "늘 속이 더부룩하고 가스가 차있다", value: "2303" },
                        { label: "자주 메스껍거나 울렁거린다", value: "2304" },
                        { label: "배 속에서 꾸룩거리는 소리가 난다", value: "2305" },
                        { label: "해당없음", value: "2306" }
                    ]
                },
                {
                    id: 24,
                    content: "입 안에 아래와 같은 증상이 있나요?",
                    type: 'checkbox',
                    options: [
                        { label: "입냄새가 난다", value: "2401" },
                        { label: "입이 쓰다", value: "2402" },
                        { label: "입이 마른다", value: "2403" },
                        { label: "해당 없음", value: "2404" }
                    ]
                },
                {
                    id: 25,
                    content: "트림에 관련된 증상이 있나요?",
                    type: 'checkbox',
                    options: [
                        { label: "트림을 자주 한다", value: "2501" },
                        { label: "트림이 시원하게 안 나온다", value: "2502" },
                        { label: "트림을 해도 시원하지 않다", value: "2503" },
                        { label: "해당 없음", value: "2504" }
                    ]
                },
                {
                    id: 26,
                    content: "가슴쪽 문제가 있나요?",
                    type: 'checkbox',
                    options: [
                        { label: "가슴에서 음식물이 안 내려간다", value: "2601" },
                        { label: "가슴이 답답하다", value: "2602" },
                        { label: "뻐근하고 조인다", value: "2603" },
                        { label: "가슴이 쓰린다", value: "2604" },
                        { label: "신물이 올라온다", value: "2605" },
                        { label: "해당 없음", value: "2606" }
                    ]
                },
                {
                    id: 27,
                    content: "숨이 차거나 한숨을 많이 쉬나요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "2701" },
                        { label: "아니요", value: "2702" }
                    ]
                },
                {
                    id: 28,
                    content: "목에 뭔가가 걸린 것 같은데 잘 뱉어지지 않나요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "2801" },
                        { label: "아니요", value: "2802" }
                    ]
                },
                {
                    id: 29,
                    content: "명치 뒤에 해당하는 등이 결리나요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "2901" },
                        { label: "아니요", value: "2902" }
                    ]
                },
                {
                    id: 30,
                    content: "다크서클이 심한가요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "3001" },
                        { label: "아니요", value: "3002" }
                    ]
                },
            ]
        }
    ],

    sectionData4: [
        {
            sectionId: 7,
            title: "음주와 흡연",
            questions: [
                {
                    id: 31,
                    content: "간수치가 높은가요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "3101" },
                        { label: "아니요", value: "3102" },
                        { label: "모른다", value: "3103" }
                    ]
                },
                {
                    id: 32,
                    content: "숨이 찬가요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "3201" },
                        { label: "아니요", value: "3202" }
                    ]
                },
                {
                    id: 33,
                    content: "가래가 많이 뱉어지나요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "3301" },
                        { label: "아니요", value: "3302" }
                    ]
                }
            ]
        },
    ],

    sectionData5: [
        {
            sectionId: 8,
            title: "화(怒)",
            questions: [
                {
                    id: 34,
                    content: "성격이 급한 편인가요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "3401" },
                        { label: "아니요", value: "3402" }
                    ]
                },
                {
                    id: 35,
                    content: "머리 및 얼굴에 아래와 같은 증상이 있나요?",
                    type: 'checkbox',
                    options: [
                        { label: "머리가 아프다", value: "3501" },
                        { label: "어지럽다", value: "3502" },
                        { label: "얼굴이 붉은 편이다", value: "3503" },
                        { label: "눈이 잘 충혈된다", value: "3504" },
                        { label: "입이 마른다", value: "3505" },
                        { label: "해당 없음", value: "3506" }
                    ]
                },
                {
                    id: 36,
                    content: "잠을 잘 자나요?",
                    type: 'checkbox',
                    options: [
                        { label: "아예 잠을 못잔다", value: "3601" },
                        { label: "자주 중간에 깬다", value: "3602" },
                        { label: "쉽게 잠에 들기 어렵다", value: "3603" },
                        { label: "꿈을 많이 꾼다", value: "3604" },
                        { label: "해당 없음", value: "3605" }
                    ]
                },
                {
                    id: 37,
                    content: "가슴쪽에 문제가 있나요?",
                    type: 'checkbox',
                    options: [
                        { label: "가슴이 두근거린다", value: "3701" },
                        { label: "가슴이 답답하다", value: "3702" },
                        { label: "한숨을 잘 쉰다", value: "3703" },
                        { label: "가슴이 짓누르는 것 같다", value: "3704" },
                        { label: "가슴이 쓰리다", value: "3705" },
                        { label: "해당 없음", value: "3706" }
                    ]
                },
                {
                    id: 38,
                    content: "숨이 차거나 한숨을 많이 쉬나요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "3801" },
                        { label: "아니요", value: "3802" }
                    ]
                },
                {
                    id: 39,
                    content: "허리가 아프고 다리에 힘이 없나요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "3901" },
                        { label: "아니요", value: "3902" }
                    ]
                },
                {
                    id: 40,
                    content: "눈에 아래와 같은 증상이 있나요?",
                    type: 'checkbox',
                    options: [
                        { label: "뻑뻑하고 건조하다", value: "4001" },
                        { label: "침침하다", value: "4002" },
                        { label: "시리다", value: "4003" },
                        { label: "충혈된다", value: "4004" },
                        { label: "해당 없음", value: "4005" }
                    ]
                },
                {
                    id: 41,
                    content: "목에 뭔가가 걸린 것 같은데 잘 뱉어지지 않나요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "4101" },
                        { label: "아니요", value: "4102" }
                    ]
                }
            ]
        }
    ],

    sectionData6: [
        {
            sectionId: 9,
            title: "사(思), 우(憂)",
            questions: [
                {
                    id: 42,
                    content: "자주 피곤하고 쉬어도 피곤이 안 풀리나요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "4201" },
                        { label: "아니요", value: "4202" }
                    ]
                },
                {
                    id: 43,
                    content: "얼굴 빛이 안 좋은가요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "4301" },
                        { label: "아니요", value: "4302" }
                    ]
                },
                {
                    id: 44,
                    content: "소화에 문제가 있나요?",
                    type: 'checkbox',
                    options: [
                        { label: "자주 체한다", value: "4401" },
                        { label: "배가 자주 빵빵해진다", value: "4402" },
                        { label: "변이 무르거나 설사를 한다", value: "4403" },
                        { label: "변비다", value: "4404" },
                        { label: "입맛이 없다", value: "4405" },
                        { label: "해당 없음", value: "4406" }
                    ]
                },
                {
                    id: 45,
                    content: "가슴쪽에 문제가 있나요?",
                    type: 'checkbox',
                    options: [
                        { label: "가슴이 두근거린다", value: "4501" },
                        { label: "가슴이 답답하다", value: "4502" },
                        { label: "한숨을 잘 쉰다", value: "4503" },
                        { label: "가슴이 짓누르는 것 같다", value: "4504" },
                        { label: "가슴이 쓰리다", value: "4505" },
                        { label: "해당 없음", value: "4506" }
                    ]
                },
                {
                    id: 46,
                    content: "요즘 자주 깜빡거리는 것 같나요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "4601" },
                        { label: "아니요", value: "4602" }
                    ]
                },
                {
                    id: 47,
                    content: "식은땀이 나고 열이 올라오는 것 같나요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "4701" },
                        { label: "아니요", value: "4702" }
                    ]
                },
                {
                    id: 48,
                    content: "목에 뭔가가 걸린 것 같은데 잘 뱉어지지 않나요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "4801" },
                        { label: "아니요", value: "4802" }
                    ]
                },
                {
                    id: 49,
                    content: "눈에 아래와 같은 증상이 있나요?",
                    type: 'checkbox',
                    options: [
                        { label: "뻑뻑하고 건조하다", value: "4901" },
                        { label: "침침하다", value: "4902" },
                        { label: "시리다", value: "4903" },
                        { label: "충혈된다", value: "4904" },
                        { label: "해당 없음", value: "4905" }
                    ]
                },
                {
                    id: 50,
                    content: "목소리가 작고 목소리에 힘이 없다",
                    type: 'radio',
                    options: [
                        { label: "네", value: "5001" },
                        { label: "아니요", value: "5002" }
                    ]
                },
                {
                    id: 51,
                    content: "잠은 잘 자나요?",
                    type: 'checkbox',
                    options: [
                        { label: "심한 불면", value: "5101" },
                        { label: "자주 깬다", value: "5102" },
                        { label: "쉽게 잠에 들기 어렵다", value: "5103" },
                        { label: "꿈을 많이 꾼다", value: "5104" },
                        { label: "없음", value: "5105" }
                    ]
                }
            ]
        },
    ],

    sectionData7: [
        {
            sectionId: 10,
            title: "공(恐)",
            questions: [
                {
                    id: 52,
                    content: "가슴쪽에 문제가 있나요?",
                    type: 'checkbox',
                    options: [
                        { label: "가슴이 두근거린다", value: "5201" },
                        { label: "가슴이 답답하다", value: "5202" },
                        { label: "한숨을 잘 쉰다", value: "5203" },
                        { label: "가슴이 짓누르는 것 같다", value: "5204" },
                        { label: "가슴이 쓰리다", value: "5205" }
                    ]
                }
            ]
        }
    ],

    sectionData8: [
        {
            sectionId: 11,
            title: "육체적 노상(勞傷)",
            questions: [
                {
                    id: 53,
                    content: "근골격계에 문제가 있나요?",
                    type: 'checkbox',
                    options: [
                        { label: "허리가 아프다", value: "5301" },
                        { label: "어깨가 뻐근하다", value: "5302" },
                        { label: "무릎이 아프다", value: "5303" },
                        { label: "없음", value: "5304" }
                    ]
                },
                {
                    id: 54,
                    content: "자주 피곤하고 쉬어도 피곤이 안 풀리나요?",
                    type: 'radio',
                    options: [
                        { label: "네", value: "5401" },
                        { label: "아니요", value: "5402" }
                    ]
                }
            ]
        }
    ],

    sectionData9: [
        {
            sectionId: 12,
            title: "정신적 노상(勞傷)",
            questions: [
                {
                    id: 55,
                    content: "소화에 문제가 있나요?",
                    type: "checkbox",
                    options: [
                        { label: "자주 체한다", value: "5501" },
                        { label: "배가 자주 빵빵해진다", value: "5502" },
                        { label: "변이 무르거나 설사를 한다", value: "5503" },
                        { label: "변비다", value: "5504" },
                        { label: "입맛이 없다", value: "5505" },
                        { label: "없음", value: "5506" }
                    ]
                },
                {
                    id: 56,
                    content: "가슴쪽에 문제가 있나요?",
                    type: "checkbox",
                    options: [
                        { label: "가슴이 두근거린다", value: "5601" },
                        { label: "가슴이 답답하다", value: "5602" },
                        { label: "한숨을 잘 쉰다", value: "5603" },
                        { label: "가슴이 짓누르는 것 같다", value: "5604" },
                        { label: "가슴이 쓰리다", value: "5605" }
                    ]
                },
                {
                    id: 57,
                    content: "요즘 자주 깜빡거리는 것 같나요?",
                    type: "radio",
                    options: [
                        { label: "네", value: "5701" },
                        { label: "아니요", value: "5702" }
                    ]
                },
                {
                    id: 58,
                    content: "식은땀이 나고 열이 올라오는 것 같나요?",
                    type: "radio",
                    options: [
                        { label: "네", value: "5801" },
                        { label: "아니요", value: "5802" }
                    ]
                },
                {
                    id: 59,
                    content: "목에 뭔가가 걸린 것 같은데 잘 뱉어지지 않나요?",
                    type: "radio",
                    options: [
                        { label: "네", value: "5901" },
                        { label: "아니요", value: "5902" }
                    ]
                },
                {
                    id: 60,
                    content: "목소리가 작고 목소리에 힘이 없나요?",
                    type: "radio",
                    options: [
                        { label: "네", value: "6001" },
                        { label: "아니요", value: "6002" }
                    ]
                },
                {
                    id: 61,
                    content: "긴장하면 어떤 현상이 나타나나요?",
                    type: "checkbox",
                    options: [
                        { label: "소변을 자주 본다", value: "6101" },
                        { label: "대변을 자주 본다", value: "6102" },
                        { label: "대변이 무르다", value: "6103" },
                        { label: "해당 없음", value: "6104" }
                    ]
                },
                {
                    id: 62,
                    content: "다크서클이 심한가요?",
                    type: "radio",
                    options: [
                        { label: "네", value: "6201" },
                        { label: "아니요", value: "6202" }
                    ]
                }
            ]
        }
    ],

    sectionData10: [
        {
            sectionId: 13,
            title: "기타",
            questions: [
                {
                    id: 63,
                    content: "평소 대변이 어떤가요?",
                    type: "radio",
                    options: [
                        { label: "문제 없이 잘 본다", value: "6301" },
                        { label: "변비다", value: "6302" },
                        { label: "변이 무른 편이다", value: "6303" },
                        { label: "긴장하면 설사를 한다", value: "6304" }
                    ]
                },
                {
                    id: 64,
                    content: "어떤 부분에 관심이 많나요?",
                    type: "checkbox",
                    options: [
                        { label: "소화 개선", value: "6401" },
                        { label: "순환 개선", value: "6402" },
                        { label: "면역 증진", value: "6403" },
                        { label: "뼈, 관절 건강", value: "6404" },
                        { label: "항염, 향균작용", value: "6405" },
                        { label: "눈 건강", value: "6406" },
                        { label: "다이어트", value: "6407" }
                    ]
                }
            ]
        }
    ],

};

