export const HealthDetailSymptomScores = (response) => {
    // response 객체에서 answers 배열로 변환
    const answers = Object.values(response)
        .flatMap(innerObject => Object.values(innerObject))
        .flat();

    // 각 value에 대한 점수 초기화
    const scoreValues = [
        {
            id: 1,
            content: "식체위완",
            value: 0,
            rules: ['2001', '2101', '2203', '2204', '2205', '2301', '2302', '2501', '2502', '2503',
                '2601', '2605', '4401', '5501',]
        },
        {
            id: 2,
            content: "비기허(비허)",
            value: 0,
            rules: ['2001', '2101', '2301', '2303', '2304', '2501', '2601', '2801', '3501', '3502',
                '3601', '3602', '3603', '3604', '4101', '4201', '4301', '4401', '4402', '4403',
                '4404', '4405', '4801', '5001', '5101', '5102', '5103', '5104', '5401', '5501',
                '5502', '5503', '5504', '5505', '5901', '6001', '6101', '6201',]
        },
        {
            id: 3,
            content: "습담",
            value: 0,
            rules: ['2001', '2101', '2301', '2303', '2304', '2305', '2501', '2502', '2503', '2901',
                '3001', '3301', '3501', '3502', '3705', '4401', '4402', '4403', '4404', '4405',
                '4505', '5205', '5501', '5502', '5503', '5504', '5505', '5605', '6101', '6201',]
        },
        {
            id: 4,
            content: "간기승비",
            value: 0,
            rules: ['2402', '2701', '3801',]
        },
        {
            id: 5,
            content: "간실",
            value: 0,
            rules: ['2201', '2202', '3101', '3401', '3501', '3502', '3503', '3504', '3601', '3602',
                '3603', '3604', '4004', '4904', '5101', '5102', '5103', '5104',]
        },
        {
            id: 6,
            content: "음허",
            value: 0,
            rules: ['2403', '3505', '3901', '4001', '4002', '4003', '4404', '4601', '4701', '4901',
                '4902', '4903', '5504', '5701', '5801',]
        },
        {
            id: 7,
            content: "심허",
            value: 0,
            rules: ['2502', '2503', '2601', '2602', '2603', '2604', '2605', '2701', '2801', '2901',
                '3201', '3601', '3602', '3603', '3604', '3701', '3702', '3703', '3704', '3705',
                '3801', '4101', '4201', '4301', '4501', '4502', '4503', '4504', '4505', '4601',
                '4801', '5101', '5102', '5103', '5104', '5201', '5202', '5203', '5204', '5205',
                '5401', '5601', '5602', '5603', '5604', '5605', '5701', '5901',]
        },
        {
            id: 8,
            content: "기체",
            value: 0,
            rules: ['2801', '3301', '3601', '3602', '3603', '3604', '3701', '3702', '3703', '3704',
                '3705', '4101', '4501', '4502', '4503', '4504', '4505', '4801', '5101',
                '5102', '5103', '5104', '5201', '5202', '5203', '5204', '5205', '5601', '5602',
                '5603', '5604', '5605', '5901',]
        },
        {
            id: 9,
            content: "어혈",
            value: 0,
            rules: ['2601', '2602', '2603', '3701', '3702', '3704', '4501', '4502', '4503', '4504',
                '5201', '5202', '5203', '5204', '5601', '5602', '5603', '5604',]
        },
        {
            id: 10,
            content: "폐허",
            value: 0,
            rules: ['2701', '3801', '4201',]
        },
        {
            id: 11,
            content: "위기상역",
            value: 0,
            rules: ['2302', '2401', '2605',]
        },
        {
            id: 12,
            content: "대장습한",
            value: 0,
            rules: []
        }
    ]

    // 각 answer에 대해 점수 계산
    answers.forEach(answer => {
        scoreValues.forEach((symptom, idx) => {
            if (symptom.rules.includes(answer)) {
                scoreValues[idx].value += 1; // 해당 answer가 규칙에 포함되어 있다면 점수를 증가
            }
        });
    });

    // 결과 반환
    return { answers, scoreValues };
};

