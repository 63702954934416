import React from 'react';
// import '../../css/HealthDetailCheckResult.css'; 

export const HealthDetailResultFoodSection = ({ symptom }) => {

  return (
    <div className='flex flex-col items-center justify-center pb-8 md:pb-20 ki:pb-[15vw]'>
      <div className="text-4xl font-jalnan text-sc-green md:text-6xl ki:text-[10vw] leading-tight">
        음식관리
      </div>
      <div className="mt-3 text-sm font-jalnan text-sc-black md:mt-7 ki:mt-[3.5vw] md:text-2xl ki:text-[3.5vw] leading-tight">
        계절과 건강상태에 맞춰 추천
      </div>
      <div className="w-full mt-5 h-[1px] bg-sc-green md:h-1 md:mt-10 ki:h-[0.5vw] ki:mt-[3.5vw]" />
      <div className="py-1 px-3 text-base text-white rounded-full font-jalnan bg-sc-green leading-tight mt-6 md:text-4xl md:py-2 md:px-6 md:mt-10 ki:text-[5vw] ki:py-[1vw] ki:px-[3vw] ki:mt-[3.5vw]">
        추천음식
      </div>
      <div
        className="mt-2 text-base font-jalnan text-center text-sc-black md:text-3xl md:mt-5 ki:text-[5vw] ki:mt-[2vw] break-words"
      >
        {symptom.recommendFood}
      </div>
      <div className="py-1 px-3 text-base text-white rounded-full font-jalnan bg-sc-orange leading-tight mt-5 md:text-4xl md:py-2 md:px-6 md:mt-12 ki:text-[5vw] ki:py-[1vw] ki:px-[3vw] ki:mt-[5vw]">
        피해야하는 음식
      </div>
      <div
        className="mt-2 text-base font-jalnan text-center text-sc-black md:text-3xl md:mt-5 ki:text-[5vw] ki:mt-[2vw] break-words"
      >
        {symptom.avoidFood}
      </div>

      <div className='flex flex-col w-full items-center justify-between mt-6 text-center text-white rounded-lg bg-sc-black font-jalnan md:rounded-2xl md:mt-12 ki:rounded-3xl ki:mt-[6vw]'>
        <div className="mt-3 text-xl leading-tight md:mt-10 md:text-4xl ki:mt-[4vw] ki:text-[5vw]">
          총평
        </div>
        <div className="mt-4 mb-4 text-base leading-tight mx-7 md:my-10 md:text-3xl md:mx-12 ki:my-[4vw] ki:text-[3.5vw] ki:mx-[4vw] ki:">
          {symptom.overall}
        </div>
      </div>
    </div>
  );
};
