import React from 'react';
import '../../css/HealthDetailCheckResult.css';

export const HealthDetailShowBox = ({ onNext }) => {

    return (
        <div
            className={`show-box mb-5 w-[90vw] left-5 text-center bg-black rounded-full h-20 flex flex-col font-jalnan items-center justify-center md:mb-10 md:h-48 ki:h-[25vw] ki:mb-[7vw]`}
            onClick={onNext}
        >
            <div className="show-box-title">건강상태를 상세하게 체크해보세요</div>
            <div className="show-box-start">시작하기</div>
        </div>
    );

};