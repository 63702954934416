import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom'; // React Router v6 사용시
import './css/common.css';
import logoImage from './img/top_logo.png';
import axios from "axios";
import {baseUrl} from "../../base";
import {signOut} from "./signout";
import {useAuth} from "../context";

const AdminSymptom = () => {

    const navigate = useNavigate();
    const [symptoms, setSymptoms] = useState([]);
    const auth = useAuth();

    useEffect(() => {
        axios.post(`${baseUrl}/v1/verify/access-token`)
            .then(res => {
                if(res.data) {
                    auth.login(res.data);
                }
            })
            .catch(err => {
                console.log(err);
                navigate("/care/manager");
            })
    },[]);

    useEffect( () => {
        axios.get(baseUrl + '/v1/symptoms')
            .then(response => {
                setSymptoms(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <div>
            <div id="top_area">
                <div class="top_logo"><img src={logoImage} alt="top logo" /></div>
                <div class="topmenu_all">
                    <button onClick={() => { navigate("/care/manager/stats"); }}>통계관리</button>
                    <button onClick={() => { navigate("/care/manager/product"); }}>건기식관리</button>
                    <button onClick={() => { navigate("/care/manager/program"); }}>프로그램관리</button>
                    <button onClick={() => { navigate("/care/manager/symptom"); }} class="active">병증관리</button>
                </div>
                <div class="right_area">
                    <button onClick={() => signOut(navigate, auth)}>Logout</button>
                </div>
            </div>

            <div id="wrap">
                <div class="top">
                    <div class="tit">병증관리</div>

                </div>
                <section>
                    <p class="sub_text"> 목록</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="list">
                        <thead>
                            <tr>
                                <td>No.</td>
                                <td>병증</td>
                                <td>키워드</td>
                                <td>공개설명</td>
                                <td>정보수정</td>
                            </tr>
                        </thead>
                        <tbody>
                            {symptoms.map((symptoms, index) => (
                                <tr key={index}>
                                    <td>{12 - index}</td>
                                    <td>{symptoms.name}</td>
                                    <td>{symptoms.keyword}</td>
                                    <td>{symptoms.description}</td>
                                    <td><button onClick={() => navigate(`/care/manager/symptom/${symptoms.id}/edit`)}>정보수정</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            </div>


            <div class="footer">
                <p>© SLOW LIFE TOGETHER</p>
            </div>

        </div>
    );
}

export default AdminSymptom;
