import React from 'react';
import image from '../../../assets/img_product_01.png'
import { baseUrl } from "../../../base";

export const MobileProgram = ({ program }) => {
    
    if (!program || !program.url) {
        return <div>프로그램 정보를 찾을 수 없습니다.</div>;
    }

    return (
        <div>
            <div className='w-full flex flex-col justify-center items-center pt-3 pb-3 md:pt-6 ki:pt-[3.5vw]'>
                <video src={`${baseUrl}/${program.url}`} alt="test" className='w-full h-auto' autoPlay muted loop controls></video>
                <label className='mt-3 text-xl leading-tight text-sc-black font-jalnan md:mt-6 md:text-5xl ki:text-[5vw] ki:mt-[1.5vw]'>
                    프로그램에 대한 설명
                </label>
            </div>
        </div>
    );
};