// NavigationBar.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
// import '../../css/HealthDetailCheckMain.css';

export const TopNavigationBar = () => {

  const navigate = useNavigate();
  
  const goHome = () => {
    navigate('/care/healthresult', { state: { isMotion: false } });
  };

  return (
    <nav className='fixed top-0 z-50 flex flex-col justify-end w-full h-16 bg-white md:h-32 ki:h-[17vw]'>
      <div className='flex items-center justify-between w-full h-[62px] px-5 text-center fixed top-0 sm:px-3 md:px-10 md:h-[124px] ki:h-[16.7vw] ki:px-[3.5vw]'>
        <div className="text-2xl font-jalnan text-sc-green sm:text-xl md:text-5xl ki:text-[5vw]">상세건강체크</div>
        <div>
        <button className='outline-none cursor-none text-lg w-28 text-sc-green rounded-full font-jalnan border-[1px] px-4 py-1 border-sc-green sm:text-base md:border-2 md:w-[30vw] md:text-[4vw] md:h-[10vw] ki:border-4 ki:w-[30vw] ki:text-[4vw] ki:h-[10vw]' onClick={ goHome }>돌아가기</button>
        </div>
      </div>
      <div className="w-full mt-3 h-0.5 bg-sc-green md:h-1 ki:h-[0.3vw]"></div>
    </nav>
  );

};
