import React, { useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import '../../css/home.css';
import image from '../../assets/img_loading_02.png'
import TimeoutRedirect from '../TimeoutRedirect';

export const HealthInfoSendCompleted = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const isDetail = location.state.isDetail;

    const goHome = () => {
        if (isDetail) {
            navigate('/care/healthdetailresult', { state: { isMotion: false } });
        } else {
            navigate('/care/healthresult', { state: { isMotion: false } });
        }
    }

    return (
        <TimeoutRedirect redirectPath="/care">
            <main className='flex flex-col items-center justify-center w-screen h-screen'>
                <img src={image} className='w-[60vw]' alt="test" />
                <span className='text-xl md:mb-2 md:text-4xl leading-tight text-sc-black font-jalnan sm:text-lg ki:mb-[1vw] ki:text-[5vw]'>내 폰으로</span>
                <span className='text-xl md:text-4xl leading-tight text-sc-black font-jalnan sm:text-lg ki:text-[5vw]'>전송되었습니다.</span>
                <button className='outline-none cursor-none mt-10 text-lg w-28 text-sc-black rounded-full font-jalnan border-[1px] px-4 py-1 border-sc-black sm:text-base md:border-2 md:mt-20 md:w-[30vw] md:text-[4vw] md:h-[10vw] ki:mt-[10vw] ki:border-4 ki:w-[30vw] ki:text-[4vw] ki:h-[10vw]' onClick={goHome}>돌아가기</button>
            </main>
        </TimeoutRedirect>
    );
}
