// HealthDetailCheckQuestionCheckbox.js
import React from 'react';
import '../../../css/HealthDetailCheckMain.css'; // CSS 파일 임포트

export const HealthDetailCheckQuestionCheckbox = ({ id, question, onChange, checkedValues, isShow }) => {

  return (
    <div id={id}>
      <div className='w-full p-5 md:p-10 ki:p-[5vw]'>
        <label className='text-xl leading-tight font-jalnan md:text-4xl ki:text-[5vw]'>{question.content}</label>
        {question.options.map((option, index) => (
          <div key={index} className='flex justify-start check__line mt-3 md:mt-10 ki:mt-[5vw]'>
            <input
              type="checkbox"
              name={option.label}
              value={option.value}
              onChange={onChange}
              id={option.value}
              checked={checkedValues.includes(option.value)}
            />
            <label htmlFor={option.value} className='leading-tight text-xl font-jalnan md:text-4xl ki:text-[5vw]'>{option.label}</label>
          </div>
        ))}
      </div>
      <div className={`h-0.5 mx-5 ${isShow ? 'bg-sc-gray2' : 'bg-sc-white'} md:mx-10 md:h-1 ki:h-[0.5vw] ki:mx-[5vw]`}></div>
    </div>
  );
};