import { Route, Routes} from 'react-router-dom';
import { HealthFunctionalFood, Home, InsertInformation} from './pages';

import React from 'react';
import { HealthDetailCheckMain } from './pages/HealthDetailCheck';
import { ResultTest } from './pages/healthResult/HealthCheckResult';
import MobileLinkInfo from './pages/link/MobileLinkInfo';
import { HealthInfoSend } from './pages/sendInfo/HealthInfoSend';
import { HealthInfoSendCompleted } from './pages/sendInfo/HealthInfoSendCompleted';
import { HealthDetailCheckResult } from './pages/healthDetailResult/HealthDetailCheckResult';

const App = () => {
  return (
      <Routes>
        <Route path="/care/" element={<Home />} />
        <Route path="/mobile/care" element={<Home />} />
        <Route path="/mobile/care/" element={<Home />} />
        <Route path="/mobile//care" element={<Home />} />
        <Route path="/mobile//care/" element={<Home />} />
        <Route
            path="/care/HeathFunctionalFoot"
            element={<HealthFunctionalFood />}
        />
        <Route path="/care/InsertInformations" element={<InsertInformation />} />

        <Route path="/care/healthresult" element={<ResultTest />} />

        <Route
            path="/care/healthdetailcheck"
            element={<HealthDetailCheckMain />}
        />
        <Route
            path="/care/healthdetailresult"
            element={<HealthDetailCheckResult />}
        />

        <Route path="/care/mobile/:userId" element={<MobileLinkInfo />} />
        <Route path="/care/send" element={<HealthInfoSend />} />
        <Route path="/care/sendCompleted" element={<HealthInfoSendCompleted />} />
      </Routes>
  );
};

export default App;
