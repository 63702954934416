import React, {useEffect, useState} from 'react';
// import '../../css/HealthDetailCheckResult.css';
import image from '../../../assets/img_product_01.png'
import {baseUrl} from "../../../base";

export const HealthDetailResultProgram = ({programs}) => {
    const [num, setNum] = useState(0);
    const [length, setLength] = useState(0);
    useEffect(() => {
        setLength(programs.length);
    },[programs])
    const randomProgramUrl = () => {
        const random =  Math.floor(Math.random() * programs.length);
        setNum(random);
    }

    return (
        <div>
            <div className='flex flex-col items-center pt-5 pb-3 md:pt-6 ki:pt-[2.5vw]'>
                <video src={`${baseUrl}/${programs[num].url}`} alt="test" className='w-full h-auto' autoPlay muted loop controls></video>
                { programs.length > 1 && (
                    <div className='flex justify-center w-full'>
                    <button className='outline-none cursor-none mt-3 text-sc-green px-4 py-1 border-[1px] border-sc-green rounded-full font-jalnan md:mt-6 md:text-[4vw] md:w-[50vw] md:p-3 md:border-2 ki:text-[5vw] ki:border-4 ki:p-[1vw] ki:w-[60vw] ki:mt-[1.5vw]' onClick={randomProgramUrl} >
                        다음 프로그램 더보기
                        </button>
                </div>
                ) }
            </div>
        </div>
    );
};
