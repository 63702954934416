import React, { useState, useEffect } from 'react';
import '../../../css/HealthDetailCheckMain.css';
import { HealthDetailCheckQuestionRadio, HealthDetailCheckQuestionCheckbox } from '../Question'
import { HealthDetailQuestionList } from '../HealthDetailQuestionList';


export const HealthDetailCheckSection9 = ({ onSectionComplete, existingResponses }) => {
  const [responses, setResponses] = useState({});

  const [submitTrigger, setSubmitTrigger] = useState(false);

  const sectionsData = HealthDetailQuestionList.sectionData9;
  const [sectionData] = HealthDetailQuestionList.sectionData9;

  useEffect(() => {
    if (existingResponses) {
      setResponses(existingResponses);
    }
  }, [existingResponses]);

  const handleCheckboxChange = (questionId, value, isChecked, jdx, idx) => {
    // 체크박스 값이 변경될 때 이전 값을 유지하면서 변경
    setResponses((prevResponses) => {
      const previousValues = prevResponses[questionId] || [];
      if (isChecked) {
        return { ...prevResponses, [questionId]: [...previousValues, value] };
      } else {
        return { ...prevResponses, [questionId]: previousValues.filter((v) => v !== value) };
      }
    });
    setSubmitTrigger(true);
    // moveToScroll(jdx, idx);
  };

  const handleRadioChange = (questionId, value, jdx, idx) => {
    // 라디오 버튼 값이 변경될 때 값을 갱신
    setResponses((prevResponses) => ({ ...prevResponses, [questionId]: [value] }));
    setSubmitTrigger(true);
    moveToScroll(jdx, idx);
  };

  const moveToScroll = (jdx, idx) => {
    if (sectionData.sectionId === jdx && sectionData.questions.length > idx + 1) {
      const nextQ = document.getElementById(`questions-${jdx}-${idx + 1}`)

      if (window.innerWidth <= 768) {
        window.scrollTo({ top: nextQ.offsetTop - 48, behavior: 'smooth' });
      } else if (window.innerWidth < 1600) {
        window.scrollTo({ top: nextQ.offsetTop - 96, behavior: 'smooth' });
      } else {
        let offset = window.innerWidth * 0.12;
        window.scrollTo({ top: nextQ.offsetTop - offset, behavior: 'smooth' });
      }
    } else if (sectionsData[sectionsData.length - 1].sectionId > jdx) {
      const nextQ = document.getElementById(`questions-${jdx + 1}-${0}`)

      if (window.innerWidth <= 768) {
        window.scrollTo({ top: nextQ.offsetTop - 48, behavior: 'smooth' });
      } else if (window.innerWidth < 1600) {
        window.scrollTo({ top: nextQ.offsetTop - 96, behavior: 'smooth' });
      } else {
        let offset = window.innerWidth * 0.12;
        window.scrollTo({ top: nextQ.offsetTop - offset, behavior: 'smooth' });
      }
    }
  };
  
  useEffect(() => {
    if (submitTrigger) {
      handleSubmit();
      setSubmitTrigger(false); // Reset the trigger
    }
  }, [submitTrigger, responses]); // Run the effect when 'submitTrigger' changes

  const handleSubmit = () => {
    // 모든 질문이 응답되었는지 확인하는 변수입니다.
    let allQuestionsAnswered = true;

    // 모든 섹션에 대해 반복합니다.
    for (const section of sectionsData) {
      // 섹션 내의 모든 질문에 대해 반복합니다.
      for (const question of section.questions) {
        const response = responses[question.id];
        // 질문 유형에 따라 응답을 확인합니다.
        if (question.type === 'checkbox') {
          // 체크박스의 경우, 최소 한 개 이상의 옵션이 선택되어 있는지 확인합니다.
          if (!response || response.length === 0) {
            allQuestionsAnswered = false;
            break; // 한 섹션에서 응답되지 않은 질문을 찾으면 바로 중단합니다.
          }
        } else if (question.type === 'radio') {
          // 라디오의 경우, 응답이 있는지 확인합니다.
          if (!response) {
            allQuestionsAnswered = false;
            break; // 한 섹션에서 응답되지 않은 질문을 찾으면 바로 중단합니다.
          }
        }
        // 다른 유형의 질문이 있다면 여기에 추가합니다.
      }

      // 모든 질문에 대한 응답이 없는 경우 반복을 중단합니다.
      if (!allQuestionsAnswered) {
        break;
      }
    }

    // 모든 질문에 대한 응답이 있는 경우
    if (allQuestionsAnswered) {
      // 부모 컴포넌트에 응답을 전달합니다.
      onSectionComplete('health_09', responses, true);
    } else {
      // 응답되지 않은 질문이 있는 경우 경고를 표시합니다.
      onSectionComplete('health_09', responses, false);
    }
  };

  return (
    <div>
      {sectionsData.map((section, sectionIndex) => (
        <div key={sectionIndex}>
          <div className='flex items-center w-full h-12 px-5 text-base bg-sc-gray2 text-sc-black font-black md:px-10 md:h-24 md:text-4xl ki:px-[5vw] ki:text-[5vw] ki:h-[12vw]'>
            <h3>{section.title}</h3>
          </div>
          <div>
            {section.questions.map((question, idx) => {
              if (question.type === 'checkbox') {
                return (
                  <HealthDetailCheckQuestionCheckbox
                    id={`questions-${section.sectionId}-${idx}`}
                    key={question.id}
                    question={question}
                    onChange={(e) => handleCheckboxChange(question.id, e.target.value, e.target.checked, section.sectionId, idx)}
                    checkedValues={responses[question.id] || []}
                    isShow={section.questions.length !== idx + 1}
                  />
                );
              } else if (question.type === 'radio') {
                return (
                  <HealthDetailCheckQuestionRadio
                    id={`questions-${section.sectionId}-${idx}`}
                    key={question.id}
                    question={question}
                    onChange={(e) => handleRadioChange(question.id, e.target.value, section.sectionId, idx)}
                    checkedValue={responses[question.id] ? responses[question.id][0] : ''}
                    isShow={section.questions.length !== idx + 1}
                  />
                );
              } else {
                // 다른 유형의 질문에 대한 처리
                return null;
              }
            })}
          </div>
        </div>
      ))}
    </div>
  );
};
