import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './css/common.css';
import logoImage from './img/top_logo.png';
import axios from "axios";
import {baseUrl} from "../../base";
import {signOut} from "./signout";
import {useAuth} from "../context";

const AdminProduct = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [totalSize, setTotalSize] = useState(0);
    const [isSearch, setIsSearch] = useState(false);
    const itemsPerPage = 10;
    const auth = useAuth();

    useEffect(() => {
        axios.post(`${baseUrl}/v1/verify/access-token`)
            .then(res => {
                if(res.data) {
                    auth.login(res.data);
                }
            })
            .catch(err => {
                console.log(err);
                navigate("/care/manager");
            })
    },[]);

    useEffect(() => {
        if(isSearch && searchValue !== '') {
            fetchDataWithSearch();
        } else {
            fetchData();
        }
    }, [currentPage]);

    const fetchData = () => {
        axios.get(`${baseUrl}/v1/products?pageNumber=${currentPage}&windowSize=${itemsPerPage}`)
            .then(response => {
                setProducts(response.data.rows);
                setTotalSize(response.data.totalCount);
                const totalPagesCount = Math.ceil(response.data.totalCount / itemsPerPage);
                setTotalPages(totalPagesCount);

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handleSearch = () => {
            fetchDataWithSearch();
            setCurrentPage(1);
            setIsSearch(true);
    };

    const fetchDataWithSearch = () => {
        const searchQuery = searchValue ? `&search=${searchValue}` : '';

        axios.get(`${baseUrl}/v1/products?pageNumber=${currentPage}&windowSize=${itemsPerPage}${searchQuery}`)
            .then(response => {
                setProducts(response.data.rows);
                setTotalSize(response.data.totalCount);
                const totalPagesCount = Math.ceil(response.data.totalCount / itemsPerPage);
                setTotalPages(totalPagesCount);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageButtons = () => {
        const pageButtons = [];
        const maxVisiblePages = 5; // 최대 표시할 페이지 수

        let startPage = Math.max(1, Math.min(currentPage - Math.floor(maxVisiblePages / 2), totalPages - maxVisiblePages + 1));
        let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

        for (let i = startPage; i <= endPage; i++) {
            pageButtons.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={currentPage === i ? 'active' : ''}
                >
                    {i}
                </button>
            );
        }

        return pageButtons;
    };




    return (
        <div>
            <div id="top_area">
                <div className="top_logo"><img src={logoImage} alt="top logo"/></div>
                <div className="topmenu_all">
                    <button onClick={() => navigate("/care/manager/stats")}>통계관리</button>
                    <button onClick={() => navigate("/care/manager/product")} className="active">건기식관리</button>
                    <button onClick={() => navigate("/care/manager/program")}>프로그램관리</button>
                    <button onClick={() => navigate("/care/manager/symptom")}>병증관리</button>
                </div>
                <div className="right_area">
                    <button onClick={() => signOut(navigate, auth)}>Logout
                    </button>
                </div>
            </div>


            <div id="wrap">
                <div className="top">
                    <div className="tit">건기식관리</div>
                    <div className="r_area">
                        <input
                            type="text"
                            name="search"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)} // 입력 값 변경 시 상태 업데이트
                            id="search"
                            placeholder="검색어를 입력하세요."
                        />
                        <button onClick={handleSearch}>검색</button>
                        |
                        <button className="btn_point"
                                onClick={() => navigate("/care/manager/product/write")}>신규등록</button>
                    </div>
                </div>
                <section>
                    <p className="sub_text">목록</p>
                    <table border="0" cellspacing="5" cellpadding="5" className="list">
                        <thead>
                        <tr>
                            <td>No.</td>
                            <td>상품명</td>
                            <td>기본설명</td>
                            <td>키워드</td>
                            <td>상품바로가기</td>
                            <td>정보수정</td>
                        </tr>
                        </thead>
                        <tbody>
                        {products.map((product, index) => (
                            <tr key={index}>
                                <td>{totalSize - ((currentPage-1) * itemsPerPage) - index}</td>
                                <td>{product.name}</td>
                                <td>{product.description}</td>
                                <td>{product.keyword}</td>
                                <td><a href={product.link} target="_blank" rel="noopener noreferrer">바로가기</a></td>
                                <td>
                                    <button onClick={() => navigate(`/care/manager/product/${product.id}/edit`)}>정보수정
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className="number">
                        <button disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>◀
                        </button>
                        {renderPageButtons()}
                        <button disabled={currentPage === totalPages}
                                onClick={() => handlePageChange(currentPage + 1)}>▶
                        </button>
                    </div>
                </section>
            </div>

            <div className="footer">
                <p>© SLOW LIFE TOGETHER</p>
            </div>
        </div>
    );
}

export default AdminProduct;