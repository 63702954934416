import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom'; // React Router v6 사용시
import './css/common.css';
import logoImage from './img/top_logo.png';
import axios from "axios";
import {baseUrl} from "../../base";
import {signOut} from "./signout";
import {useAuth} from "../context";


const AdminProductWrite = () => {
    const [form, setForm] = useState({
        name: '',
        description: '',
        keyword: '',
        link: ''
    });
    const auth = useAuth();
    useEffect(() => {
        axios.post(`${baseUrl}/v1/verify/access-token`)
            .then(res => {
                if(res.data) {
                    auth.login(res.data);
                }
            })
            .catch(err => {
                console.log(err);
                navigate("/care/manager");
            })
    },[]);

    const navigate = useNavigate(); // React Router v6 사용시

    const handleChange = (e) => {
        const { id, value } = e.target;
        setForm((prev) => ({
            ...prev,
            [id]: value
        }))
    }

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append('name', form.name);
        formData.append('description', form.description);
        formData.append('link', form.link);
        formData.append('keyword', form.keyword);
        formData.append('file', form.file);

        try {
            axios.post(`${baseUrl}/v1/products`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            navigate('/care/manager/product');
        } catch (error) {
            console.error('There was a problem with the axios request:', error);
            navigate('/care/manager/product');
        }
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setForm((prev) => ({
            ...prev,
            file: file, // 파일 추가
        }));
    };

    return (
        <div>
            <div id="top_area">
                <div class="top_logo"><img src={logoImage} alt="top logo" /></div>
                <div class="topmenu_all">
                    <button onClick={() => { navigate("/care/manager/stats"); }}>통계관리</button>
                    <button onClick={() => { navigate("/care/manager/product"); }} class="active">건기식관리</button>
                    <button onClick={() => { navigate("/care/manager/program"); }}>프로그램관리</button>
                    <button onClick={() => { navigate("/care/manager/symptom"); }}>병증관리</button>
                </div>
                <div class="right_area">
                    <button onClick={() => signOut(navigate, auth)}>Logout</button>
                </div>
            </div>


            <div id="wrap">
                <div class="top">
                    <div class="tit">건기식 정보</div>
                    <div class="r_area">
                        <button class="btn_point" onClick={handleSubmit}>저장하기</button>
                        <button class="btn_point" onClick={() => { navigate("/care/manager/product"); }}>삭제</button>
                        <button onClick={() => { navigate("/care/manager/product"); }}>목록</button>
                    </div>
                </div>
                <section>
                    <p className="sub_text"> 기본정보</p>
                    <table border="0" cellSpacing="5" cellPadding="5" className="input_page">
                        <tbody >
                        <tr>
                            <td>상품명</td>
                            <td><input id={'name'} type="text" value={form.name} onChange={handleChange} /></td>
                        </tr>
                        <tr>
                            <td>기본설명</td>
                            <td><input id={'description'} type="text" className="long" value={form.description} onChange={handleChange} /></td>
                        </tr>
                        <tr>
                            <td>키워드</td>
                            <td><p>중복입력 시 콤마(,)로 구분해주세요. </p>
                                <input id={'keyword'} type="text" className="long" value={form.keyword} onChange={handleChange} /></td>
                        </tr>
                        <tr>
                            <td>상품 구매링크</td>
                            <td><input id={'link'} type="text" className="long" value={form.link} onChange={handleChange} /></td>
                        </tr>
                        <tr>
                            <td>이미지파일</td>
                            <input type="file" id="fileInput" onChange={handleFileChange} />
                        </tr>
                        </tbody>
                    </table>
                </section>


            </div>


            <div class="footer">
                <p>© SLOW LIFE TOGETHER</p>
            </div>
        </div>
    );
}

export default AdminProductWrite;
