import React, { useState } from 'react';
import '../../../css/insertInformation.css';

export const Component8 = ({ onNextStep }) => {

    const [clickable, setClickable] = useState(true);

    const handleAgeSelect = (bodyCondition, event) => {
        if (!clickable) return;

        setClickable(false);
        const button = event.target;
        button.classList.add('button-clicked');

        setTimeout(() => {
            onNextStep(9, 'bodyCondition', bodyCondition);
            button.classList.remove('button-clicked');
            setClickable(true);
        }, 1000);
    };

    return (
        <div className='flex flex-col'>
            <label
                className='pt-5 text-5xl text-center font-jalnan pb-10 text-white sm:text-4xl md:pt-0 md:text-[11vw] md:pb-10 ki:pt-[6vw] ki:text-[11vw] ki:pb-[10vw]'>
                몸 상태
            </label>
            <div
                className='flex flex-col items-center justify-between mb-5 ml-5 mr-5 md:mb-10 md:mr-20 md:ml-20 ki:mb-[6vw] ki:mr-[6vw] ki:ml-[6vw]'>
                <button
                    className='outline-none cursor-none w-full p-3 text-2xl text-white border-[1px] mb-5 font-jalnan rounded-xl sm:text-xl md:text-[5vw] md:mb-10 md:h-[14vw] md:border-2 md:rounded-2xl ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:mb-[3vw] ki:h-[14vw]' onClick={(e) => handleAgeSelect('801', e)}>
                    쉽게 피로해진다
                </button>
                <button
                    className='outline-none cursor-none w-full p-3 text-2xl text-white border-[1px] mb-5 font-jalnan rounded-xl sm:text-xl md:text-[5vw] md:mb-10 md:h-[14vw] md:border-2 md:rounded-2xl ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:mb-[3vw] ki:h-[14vw]' onClick={(e) => handleAgeSelect('802', e)}>
                    눈이 자주 충혈된다
                </button>
                <button
                    className='outline-none cursor-none w-full p-3 text-2xl text-white border-[1px] mb-5 font-jalnan rounded-xl sm:text-xl md:text-[5vw] md:mb-10 md:h-[14vw] md:border-2 md:rounded-2xl ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:mb-[3vw] ki:h-[14vw]' onClick={(e) => handleAgeSelect('803', e)}>
                    다리에 쥐가 잘 난다
                </button>
                <button
                    className='outline-none cursor-none w-full p-3 text-2xl text-white border-[1px] mb-5 font-jalnan rounded-xl sm:text-xl md:text-[5vw] md:mb-10 md:h-[14vw] md:border-2 md:rounded-2xl ki:border-4 ki:rounded-3xl ki:text-[6vw] ki:mb-[3vw] ki:h-[14vw]' onClick={(e) => handleAgeSelect('804', e)}>
                    해당사항없음
                </button>
            </div>
        </div>
    );

}