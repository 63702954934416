import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom'; // React Router v6 사용시
import './css/common.css';
import logoImage from './img/top_logo.png';
import axios from "axios";
import {baseUrl} from "../../base";
import {signOut} from "./signout";
import {useAuth} from "../context";

const AdminSymptomEdit = () => {
    const { symptomId } = useParams();
    const auth = useAuth();
    const [form, setForm] = useState({
        name: '',
        description: '',
        keyword: '',
        recommendFood: '',
        avoidFood: '',
        overall: '',
    });
    const [productMaps, setProductMaps] = useState([]);
    const [programMaps, setProgramMaps] = useState([]);
    const [products, setProducts] = useState([]);
    const [programs, setPrograms] = useState([]);
    useEffect(() => {
        axios.post(`${baseUrl}/v1/verify/access-token`)
            .then(res => {
                if(res.data) {
                    auth.login(res.data);
                }
            })
            .catch(err => {
                console.log(err);
                navigate("/care/manager");
            })
    },[]);

    const navigate = useNavigate(); // React Router v6 사용시

    useEffect( () => {
        axios.get(`${baseUrl}/v1/symptoms/${symptomId}`)
            .then(response => {
                const { data } = response;
                setForm({
                    name: data.name,
                    description: data.description,
                    keyword: data.keyword,
                    recommendFood: data.recommendFood,
                    avoidFood: data.avoidFood,
                    overall: data.overall,
                });
                setProductMaps(prevState => {
                    return data.products.map((product, index) => {
                        return {
                            ...product,
                            index: index + 1
                        };
                    });
                });
                setProgramMaps(prevState => {
                    return data.programs.map((program, index) => {
                        return {
                            ...program,
                            index: index + 1
                        };
                    });
                });
            })
            .catch(error => {
                console.error('There was a symptom fetching symptom data:', error);
            });

        axios.get(`${baseUrl}/v1/products?type=all`)
            .then(response => {
                const { data } = response;
                setProducts(data.map(product => ({
                    id: product.id,
                    name: product.name
                })));
            })
            .catch(error => {
                console.error('There was a symptom fetching symptom data:', error);
            });

         axios.get(`${baseUrl}/v1/programs?type=all`)
            .then(response => {
                const { data } = response;
                setPrograms(data.map(program => ({
                    id: program.id,
                    name: program.name
                })));

            })
            .catch(error => {
                console.error('There was a symptom fetching symptom data:', error);
            });

    }, []);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setForm((prev) => ({
            ...prev,
            [id]: value
        }))
    }

    const handleSubmit = () => {
        const updateData = {
            ...form,
            products: productMaps,
            programs: programMaps,
        }
         axios.put(`${baseUrl}/v1/symptoms/${symptomId}`, updateData)
            .then(response => {
                navigate("/care/manager/symptom");
            })
            .catch(error => {
                console.error('There was a problem updating the program:', error);
            });
    }

    const addProgramMap = () => {
        setProgramMaps(prevState => {
            return [...prevState, { index: prevState.length + 1, id: '', name: '', priority: '' }];
        });
    }

    const deleteProgramMap = (id) => {
        setProgramMaps(prevState => {
            return prevState.filter(programMap => programMap.index !== id);
        });
    };

    const addProductMap = () => {
        setProductMaps(prevState => {
            return [...prevState, { index: prevState.length + 1 , id: '', name: '', exposure:'', priority: '' }];
        });
    }

    const deleteProductMap = (id) => {
        setProductMaps(prevState => {
            return prevState.filter(productMap => productMap.index !== id);
        });
    };

    const handleProgramChange = (e, index) => {
        let { id, value } = e.target;
        if(id === 'id') {
            const program = programs.filter(program => program.id  === Number(value));
            setProgramMaps((prev) =>
                prev.map((programMap) =>
                    programMap.index === index
                        ? { ...programMap, id: program[0].id, name: program[0].name  }
                        : programMap
                )
            );
        } else {
            setProgramMaps((prev) =>
                prev.map((programMap) =>
                    programMap.index === index
                        ? {...programMap, [id]: value}
                        : programMap
                )
            );
        }
    };

    const handleProductChange = (e, index) => {
        let { id, value } = e.target;
        console.log(id);
        console.log(value);
        if(id === 'id') {
            const product = products.filter(product => product.id  === Number(value));
            setProductMaps((prev) =>
                prev.map((productMap) =>
                    productMap.index === index
                        ? { ...productMap, id: product[0].id, name: product[0].name  }
                        : productMap
                )
            );
        } else {
            setProductMaps((prev) =>
                prev.map((productMap) =>
                    productMap.index === index
                        ? {...productMap, [id]: value}
                        : productMap
                )
            );
        }
    };


    return (
        <div>
            <div id="top_area">
                <div class="top_logo"><img src={logoImage} alt="top logo" /></div>
                <div class="topmenu_all">
                    <button onClick={() => { navigate("/care/manager/stats"); }}>통계관리</button>
                    <button onClick={() => { navigate("/care/manager/product"); }}>건기식관리</button>
                    <button onClick={() => { navigate("/care/manager/program"); }}>프로그램관리</button>
                    <button onClick={() => { navigate("/care/manager/symptom"); }} class="active">병증관리</button>
                </div>
                <div class="right_area">
                    <button onClick={() => signOut(navigate, auth)}>Logout</button>
                </div>
            </div>

            <div id="wrap">
                <div class="top">
                    <div class="tit">병증 정보</div>
                    <div class="r_area">
                        <button className="btn_point" onClick={handleSubmit}>저장하기</button>
                        <button onClick={() => { navigate("/care/manager/symptom"); }}>목록</button>
                    </div>
                </div>
                <section>
                    <p class="sub_text"> 기본정보</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >
                            <tr>
                                <td>병증명</td>
                                <td><input id={'name'} type="text" value={form.name} onChange={handleChange} /></td>
                            </tr>
                            <tr>
                                <td>키워드</td>
                                <td>#<input id={'keyword'} type="text" value={form.keyword} onChange={handleChange} /></td>
                            </tr>
                            <tr>
                                <td>공개설명</td>
                                <td><input id={'description'} type="text" value={form.description} onChange={handleChange} /></td>
                            </tr>
                            <tr>
                                <td>추천 음식</td>
                                <td><input id={'recommendFood'} type="text" value={form.recommendFood} onChange={handleChange} /></td>
                            </tr>
                            <tr>
                                <td>피해야하는 음식</td>
                                <td><input id={'avoidFood'} type="text" value={form.avoidFood} onChange={handleChange} /></td>
                            </tr>
                            <tr>
                                <td>음식총평</td>
                                <td><input id={'overall'} type="text" value={form.overall} onChange={handleChange} /></td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <div class="gap30"></div>
                <section>
                    <p class="sub_text"> 연결프로그램  <button onClick={addProgramMap} >프로그램 추가</button></p>
                    <table border="0" cellspacing="5" cellpadding="5" class="list">
                        <tbody >
                            <thead>
                                <tr>
                                    <td>No.</td>
                                    <td>프로그램</td>
                                    <td>노출설정</td>
                                    <td>우선순위</td>
                                    <td>삭제여부</td>
                                </tr>
                            </thead>
                            <tbody>
                            {programMaps.map((programMap) => (
                                <tr key={programMap.index}>
                                    <td>{programMap.index}</td>
                                    <td>
                                        <select
                                            name="programName"
                                            id={'id'}
                                            value={programMap.id}
                                            onChange={(e) => handleProgramChange(e, programMap.index)}
                                        >
                                            {programs.map((program, index) => (
                                                <option key={index} value={program.id}>
                                                    {program.name}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td>
                                        <label>
                                            <input
                                                type="radio"
                                                name="exposure"
                                                value ="all"
                                                checked={programMap.exposure === 'all'}
                                                onChange={() => handleProgramChange({ target: { id: 'exposure', value: 'all' } }, programMap.index)}
                                            />
                                            모두
                                        </label>
                                        {', '}
                                        <label>
                                            <input
                                                type="radio"
                                                name="exposure"
                                                value="tablet"
                                                checked={programMap.exposure === 'tablet'}
                                                onChange={() => handleProgramChange({ target: { id: 'exposure', value: 'tablet' } }, programMap.index)}
                                            />
                                            테블릿
                                        </label>
                                        {', '}
                                        <label>
                                            <input
                                                type="radio"
                                                name="exposure"
                                                value="mobile"
                                                checked={programMap.exposure === 'mobile'}
                                                onChange={() => handleProgramChange({ target: { id: 'exposure', value: 'mobile' } }, programMap.index)}
                                            />
                                            모바일
                                        </label>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            id={'priority'}
                                            value={programMap.priority}
                                            onChange={(e) => handleProgramChange(e, programMap.index)}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={() => deleteProgramMap(programMap.index)}>삭제</button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </tbody>
                    </table>
                </section>
                <div class="gap30"></div>
                <section>
                    <p class="sub_text"> 연결 건기식  <button onClick={addProductMap}>건기식 추가</button></p>
                    <table border="0" cellspacing="5" cellpadding="5" class="list">
                        <tbody>
                            <thead>
                                <tr>
                                    <td>No.</td>
                                    <td>상품명</td>
                                    <td>우선순위</td>
                                    <td>삭제여부</td>
                                </tr>
                            </thead>
                            <tbody>
                                {productMaps.map(productMap => (
                                    <tr key={productMap.index}>
                                        <td>{productMap.index}</td>
                                       <td>
                                            <select
                                                name="productName"
                                                id={'id'}
                                                value={productMap.id}
                                                onChange={(e) => handleProductChange(e, productMap.index)}
                                            >
                                                {products.map((product, index) => (
                                                    <option key={index} value={product.id}>
                                                        {product.name}
                                                    </option>
                                                ))}
                                            </select>
                                       </td>
                                        <td>
                                            <input
                                                type="text"
                                                id={'priority'}
                                                value={productMap.priority}
                                                onChange={(e) => handleProductChange(e, productMap.index)}
                                            />
                                        </td>
                                        <td><button onClick={() => deleteProductMap(productMap.index)}>삭제</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </tbody>
                    </table>
                </section>
            </div>


            <div class="footer">
                <p>© SLOW LIFE TOGETHER</p>
            </div>
        </div>
    );
}

export default AdminSymptomEdit;
