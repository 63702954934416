import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom'; // React Router v6 사용시
import './css/common.css';
import logoImage from './img/top_logo.png';
import {useAuth} from "../context";
import axios from "axios";
import {baseUrl} from "../../base";

const AdminHome = () => {
    const [form, setForm] = useState({ id: '', password: ''})
    const navigate = useNavigate(); // React Router v6 사용시
    const auth = useAuth();

    useEffect(() => {
        axios.post(`${baseUrl}/v1/verify/access-token`)
            .then(res => {

                if(res.data) {

                    auth.login(res.data);
                    navigate("/care/manager/stats");
                }
            })
            .catch(err => {
                console.log(err);
            })
    })
    const handleLogin = () => {
        axios.post(`${baseUrl}/v1/sign-in`, form)
            .then(value => {
                auth.login(form.id)
                navigate("/care/manager/stats");
            })
            .catch(err => {
                console.log(err);
            });
    }

    const handleChange = (key, e) => {
        const { value } = e.target
        setForm(prev => ({
            ...prev,
            [key]: value
        }))
    }

    return (
        <div>
            <div className="left_img"></div>
            <div id="login_area">
                <div className="wrap">
                    <div className="top_logo"><img src={logoImage} alt="top logo" /></div>
                    <input type="text" placeholder="아이디를 입력하세요." id="adminId" value={form.id} onChange={(e) => handleChange('id', e)} />
                    <input type="password" placeholder="비밀번호를 입력하세요." id="adminPw" value={form.password} onChange={e => handleChange('password', e)} />
                    <button onClick={handleLogin}>Login</button>
                    <p className="copyright">© SLOW LIFE TOGETHER</p>
                </div>
            </div>
        </div>
    );
}

export default AdminHome;
