import React, { useState } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import '../../css/home.css';
import TimeoutRedirect from '../TimeoutRedirect';
import axios from "axios";
import { baseUrl } from "../../base";

export const HealthInfoSend = () => {

    const userId = localStorage.getItem('userId');
    const navigate = useNavigate();

    const location = useLocation();
    const isDetail = location.state.isDetail;


    const [phoneNumber, setPhoneNumber] = useState('');

    const goBack = () => {
        if (isDetail) {
            navigate('/care/healthdetailresult', { state: { isMotion: false } });
        } else {
            navigate('/care/healthresult', { state: { isMotion: false } });
        }
    };

    const sendPhone = () => {
        if (phoneNumber.length < 8) { 
            alert("연락처를 입력해 주세요.");
            return
        }

        axios.put(`${baseUrl}/v1/users/${userId}/phone-number`, { phoneNumber: phoneNumber })
            .then(response => {
                console.log("success");
                navigate('/care/sendCompleted', { state: { isDetail: isDetail }});
            })
            .catch(error => {
                console.error('There was a problem fetching program data:', error);
            });
    };

    // 숫자를 클릭할 때 호출되는 함수
    const handleNumberClick = (number) => {
        if (phoneNumber.length >= 8) { return }
        setPhoneNumber((prevValue) => prevValue + number);
    };

    // 뒤로가기 버튼을 클릭할 때 호출되는 함수
    const handleDeleteClick = () => {
        const newValue = phoneNumber.slice(0, -1);
        setPhoneNumber(newValue);
    };

    return (
        <TimeoutRedirect redirectPath="/care">
            <main className='w-screen h-screen'>
                <div className='flex flex-col w-full h-full bg-white'>
                    <div className='fixed z-50 flex flex-col justify-end w-full h-16 bg-white md:h-32 ki:h-[17vw]'>
                        <div className='flex items-center justify-between w-full h-[62px] px-5 text-center fixed top-0 sm:px-3 md:px-10 md:h-[124px] ki:h-[16.7vw] ki:px-[3.5vw]'>
                            <div className="text-2xl font-jalnan text-sc-black sm:text-xl md:text-5xl ki:text-[5vw]">건강정보 전송</div>
                            <div>
                                <button className='outline-none cursor-none text-lg w-28 text-sc-black rounded-full font-jalnan border-[1px] px-4 py-1 border-sc-black sm:text-base md:border-2 md:w-[30vw] md:text-[4vw] md:h-[10vw] ki:border-4 ki:w-[30vw] ki:text-[4vw] ki:h-[10vw]' onClick={goBack}>닫힘</button>
                            </div>
                        </div>
                        <div className="w-full mt-[62px] h-0.5 bg-sc-black md:h-1 md:mt-[124px] ki:h-[0.3vw] ki:mt-[16.7vw]"></div>
                    </div>
                    <div className='flex items-center mt-[104px] justify-center md:mt-[170px] ki:mt-[20vw]'>
                        <label htmlFor="phone-number" className='text-4xl font-jalnan text-sc-black sm:text-3xl md:text-6xl ki:text-[8vw]'>010-</label>
                        <input
                            type="tel"
                            id="phone-number"
                            value={phoneNumber}
                            maxLength="8"
                            readOnly
                            className='text-4xl border-0 border-b-2 outline-none w-52 border-sc-gray font-jalnan sm:text-3xl sm:w-[168px] md:w-[416px] md:border-b-4 md:text-6xl ki:text-[8vw] ki:w-[45vw] ki:border-b-[0.5vw]'
                        />
                    </div>
                    <div className='flex flex-col items-center mt-10 sm:mt-7 md:mt-8 ki:mt-[8vw]'>
                        <div className='flex justify-between'>
                            <button 
                            className='outline-none cursor-none w-20 h-20 mr-4 text-4xl border-2 font-jalnan sm:mr-2 md:w-32 md:h-32 md:text-7xl md:mr-10 ki:w-[15vw] ki:h-[15vw] ki:border-[0.5vw] ki:mr-[3vw] ki:text-[7vw]'
                            onClick={() => handleNumberClick('1')}>1</button>
                            <button
                            className='outline-none cursor-none w-20 h-20 mr-4 text-4xl border-2 font-jalnan sm:mr-2 md:w-32 md:h-32 md:text-7xl md:mr-10 ki:w-[15vw] ki:h-[15vw] ki:border-[0.5vw] ki:mr-[3vw] ki:text-[7vw]'
                            onClick={() => handleNumberClick('2')}>2</button>
                            <button 
                            className='outline-none cursor-none w-20 h-20 text-4xl border-2 font-jalnan md:w-32 md:h-32 md:text-7xl ki:w-[15vw] ki:h-[15vw] ki:border-[0.5vw] ki:text-[7vw]'
                            onClick={() => handleNumberClick('3')}>3</button>
                        </div>
                        <div className='flex justify-between mt-5 sm:mt-3'>
                            <button 
                            className='outline-none cursor-none w-20 h-20 mr-4 text-4xl border-2 font-jalnan sm:mr-2 md:w-32 md:h-32 md:text-7xl md:mr-10 ki:w-[15vw] ki:h-[15vw] ki:border-[0.5vw] ki:mr-[3vw] ki:text-[7vw]'
                            onClick={() => handleNumberClick('4')}>4</button>
                            <button
                            className='outline-none cursor-none w-20 h-20 mr-4 text-4xl border-2 font-jalnan sm:mr-2 md:w-32 md:h-32 md:text-7xl md:mr-10 ki:w-[15vw] ki:h-[15vw] ki:border-[0.5vw] ki:mr-[3vw] ki:text-[7vw]'
                            onClick={() => handleNumberClick('5')}>5</button>
                            <button 
                            className='outline-none cursor-none w-20 h-20 text-4xl border-2 font-jalnan md:w-32 md:h-32 md:text-7xl ki:w-[15vw] ki:h-[15vw] ki:border-[0.5vw] ki:text-[7vw]'
                            onClick={() => handleNumberClick('6')}>6</button>
                        </div>
                        <div className='flex justify-between mt-5 sm:mt-3'>
                            <button 
                            className='outline-none cursor-none w-20 h-20 mr-4 text-4xl border-2 font-jalnan sm:mr-2 md:w-32 md:h-32 md:text-7xl md:mr-10 ki:w-[15vw] ki:h-[15vw] ki:border-[0.5vw] ki:mr-[3vw] ki:text-[7vw]'
                            onClick={() => handleNumberClick('7')}>7</button>
                            <button
                            className='outline-none cursor-none w-20 h-20 mr-4 text-4xl border-2 font-jalnan sm:mr-2 md:w-32 md:h-32 md:text-7xl md:mr-10 ki:w-[15vw] ki:h-[15vw] ki:border-[0.5vw] ki:mr-[3vw] ki:text-[7vw]'
                            onClick={() => handleNumberClick('8')}>8</button>
                            <button 
                            className='outline-none cursor-none w-20 h-20 text-4xl border-2 font-jalnan md:w-32 md:h-32 md:text-7xl ki:w-[15vw] ki:h-[15vw] ki:border-[0.5vw] ki:text-[7vw]'
                            onClick={() => handleNumberClick('9')}>9</button>
                        </div>
                        <div className='flex justify-between mt-5 sm:mt-3'>
                            <button 
                            className='outline-none cursor-none w-20 h-20 mr-4 text-4xl font-jalnan sm:mr-2 md:w-32 md:h-32 md:text-7xl md:mr-10 ki:w-[15vw] ki:h-[15vw] ki:mr-[3vw]'
                            onClick={() => handleNumberClick('')}></button>
                            <button
                            className='outline-none cursor-none w-20 h-20 mr-4 text-4xl border-2 font-jalnan sm:mr-2 md:w-32 md:h-32 md:text-7xl md:mr-10 ki:w-[15vw] ki:h-[15vw] ki:border-[0.5vw] ki:mr-[3vw] ki:text-[7vw]'
                            onClick={() => handleNumberClick('0')}>0</button>
                            <button 
                            className='outline-none cursor-none w-20 h-20 text-4xl border-2 font-jalnan md:w-32 md:h-32 md:text-7xl ki:w-[15vw] ki:h-[15vw] ki:border-[0.5vw] ki:text-[7vw]'
                            onClick={handleDeleteClick}>&larr;</button>
                        </div>
                    </div>
                    <div>
                        <div className="fixed bottom-0 justify-between w-full h-20 text-2xl font-jalnan md:h-[20vw] md:text-[5vw] ki:h-[20vw] ki:text-[6vw]">
                            <button className='outline-none cursor-none flex-1 text-white bg-black w-[25%] h-full' onClick={goBack}>
                                취소
                            </button>
                            <button className='outline-none cursor-none flex-1 text-white bg-sc-yellow w-[75%] h-full' onClick={sendPhone}>
                                내 폰으로 보내기
                            </button>
                        </div>
                    </div>
                </div>

            </main>
        </TimeoutRedirect>
    );
}

