import React from 'react';
import { MobileProgram } from './';

export const MobileProgramSection = ({ programs }) => {
    return (
        <div>
            <div className='flex flex-col items-center text-center'>
                <div className="text-4xl font-jalnan text-sc-blue md:text-6xl ki:text-[10vw] leading-none">
                    추천 프로그램
                </div>
                <div className="mt-3 text-sm font-jalnan text-sc-black md:mt-7 ki:mt-[3.5vw] md:text-2xl ki:text-[3.5vw] leading-none">
                    하루에 5분 투자하고 건강해지세요.
                </div>
                {programs.map((program, index) => (
                    <MobileProgram key={index} program={program} />
                ))}
            </div>
        </div>
    );
};