import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom'; // React Router v6 사용시
import './css/common.css';
import logoImage from './img/top_logo.png';
import axios from "axios";
import {baseUrl} from "../../base";
import {signOut} from "./signout";
import {useAuth} from "../context";

const AdminStatsWrite = () => {
    const { userId } = useParams();
    const navigate = useNavigate(); // React Router v6 사용시
    const auth = useAuth();

    useEffect(() => {
        axios.post(`${baseUrl}/v1/verify/access-token`)
            .then(res => {
                if(res.data) {
                    auth.login(res.data);
                }
            })
            .catch(err => {
                console.log(err);
                navigate("/care/manager");
            })
    },[]);
    const [user, setUser] = useState({
        phoneTransferYn: 0,
        phoneNumber: null,
        simpleCheck: {
            age: "",
            gender: "",
            handAndFoot: "",
            pee: "",
            feces: "",
            tongue: "",
            body: ""
        },
        detailCheck: {
            "2": "",
            "3": "",
            "4": "",
            "5": "",
            "6": "",
            "7": "",
            "8": "",
            "9": "",
            "10": "",
            "11": "",
            "12": "",
            "13": "",
            "14": "",
            "15": "",
            "16": "",
            "17": "",
            "18": "",
            "19": "",
            "20": "",
            "21": "",
            "22": "",
            "23": "",
            "24": "",
            "26": "",
            "27": "",
            "28": "",
            "29": "",
            "30": "",
            "31": "",
            "32": "",
            "33": "",
            "34": "",
            "35": "",
            "36": "",
            "37": "",
            "38": "",
            "39": "",
            "40": "",
            "41": "",
            "42": "",
            "43": "",
            "44": "",
            "45": "",
            "46": "",
            "47": "",
            "48": "",
            "49": "",
            "50": "",
            "51": "",
            "52": "",
            "53": "",
            "54": "",
            "55": "",
            "56": "",
            "57": "",
            "58": "",
            "59": "",
            "60": "",
            "61": "",
            "62": "",
            "63": "",
            "64": ""
        }
    });

    useEffect( () => {
        axios.get(`${baseUrl}/v1/users/${userId}`)
            .then(response => {
                setUser(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <div>
            <div id="top_area">
                <div class="top_logo"><img src={logoImage} alt="top logo" /></div>
                <div class="topmenu_all">
                    <button onClick={() => { navigate("/care/manager/stats"); }} class="active">통계관리</button>
                    <button onClick={() => { navigate("/care/manager/product"); }}>건기식관리</button>
                    <button onClick={() => { navigate("/care/manager/program"); }}>프로그램관리</button>
                    <button onClick={() => { navigate("/care/manager/symptom"); }}>병증관리</button>
                </div>
                <div class="right_area">
                    <button onClick={() => signOut(navigate, auth)}>Logout</button>
                </div>
            </div>

            <div id="wrap">
                <div class="top">
                    <div class="tit">고객정보</div>
                    <div class="r_area">
                        <button onClick={() => { navigate("/care/manager/stats"); }}>목록</button>
                    </div>
                </div>
                <section>
                    <p class="sub_text"> 휴대폰 전송</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >

                            <tr>
                                <td>상태</td>
                                <td>{user.phoneTransferYn ? "전송완료" : "미전송"}</td>
                            </tr>
                            <tr>
                                <td>휴대폰번호</td>
                                <td>{user.phoneNumber}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <div class="gap30"></div>
                <section>
                    <p class="sub_text"> 간편진단</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >

                            <tr>
                                <td>나이</td>
                                <td>{user.simpleCheck.age}</td>
                            </tr>
                            <tr>
                                <td>성별</td>
                                <td>{user.simpleCheck.gender}</td>
                            </tr>
                            <tr>
                                <td>손발상태</td>
                                <td>{user.simpleCheck.handAndFoot}</td>
                            </tr>
                            <tr>
                                <td>소변상태</td>
                                <td>{user.simpleCheck.pee}</td>
                            </tr>
                            <tr>
                                <td>대변상태</td>
                                <td>{user.simpleCheck.feces}</td>
                            </tr>
                            <tr>
                                <td>혀상태</td>
                                <td>{user.simpleCheck.tongue}</td>
                            </tr>
                            <tr>
                                <td>몰상태</td>
                                <td>{user.simpleCheck.body}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <div class="gap30"></div>
                <section>
                    <p class="sub_text"> 상세진단 (과거력, 현병력)</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >

                            <tr>
                                <td>가족 중에  다음 질환을 앓았던 사람이 있나요?</td>
                                <td>{user.detailCheck["2"]}</td>
                            </tr>
                            <tr>
                                <td>다음과 같은 진단을 받았거나, 현재 약물 치료 중인가요?</td>
                                <td>{user.detailCheck["3"]}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <div class="gap30"></div>
                <section>
                    <p class="sub_text"> 상세진단 (식습관 )</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >

                            <tr>
                                <td>식사시간이 규칙적인가요?</td>
                                <td>{user.detailCheck["4"]}</td>
                            </tr>
                            <tr>
                                <td>어떤 밥을 좋아하나요? </td>
                                <td>{user.detailCheck["5"]}</td>
                            </tr>
                            <tr>
                                <td>한 끼 식사 시 소요되는 시간은 어느 정도인가요?</td>
                                <td>{user.detailCheck["6"]}</td>
                            </tr>
                            <tr>
                                <td>식사할 때 몇 번 정도 씹나요?</td>
                                <td>{user.detailCheck["7"]}</td>
                            </tr>
                            <tr>
                                <td> 식사량은 어떤가요?</td>
                                <td>{user.detailCheck["8"]}</td>
                            </tr>
                            <tr>
                                <td>야식을 얼마나 자주 먹나요?</td>
                                <td>{user.detailCheck["9"]}</td>
                            </tr>
                            <tr>
                                <td>밀가루음식(면류)을 얼마나 자주 먹나요? </td>
                                <td>{user.detailCheck["10"]}</td>
                            </tr>
                            <tr>
                                <td>육류는 얼마나 자주 먹나요?</td>
                                <td>{user.detailCheck["11"]}</td>
                            </tr>
                            <tr>
                                <td>인스턴트식품은 얼마나 자주 먹나요?</td>
                                <td>{user.detailCheck["12"]}</td>
                            </tr>
                            <tr>
                                <td>최근 1년 간 술을 얼마나 자주 마시나요?</td>
                                <td>{user.detailCheck["13"]}</td>
                            </tr>
                            <tr>
                                <td>  현재 담배를 얼마나 자주 피나요?</td>
                                <td>{user.detailCheck["14"]}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <div class="gap30"></div>
                <section>
                    <p class="sub_text"> 상세진단 (기분 상태)</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >

                            <tr>
                                <td>요즘 기분은 어떤가요?  </td>
                                <td>{user.detailCheck["15"]}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <div class="gap30"></div>
                <section>
                    <p class="sub_text"> 상세진단 (노동)</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >

                            <tr>
                                <td>몸을 움직이는 일이 많나요?  </td>
                                <td>{user.detailCheck["16"]}</td>
                            </tr>
                            <tr>
                                <td>이런저런 신경 쓸 일이 많나요?  </td>
                                <td>{user.detailCheck["17"]}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <div class="gap30"></div>
                <section>
                    <p class="sub_text"> 외인</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >

                            <tr>
                                <td>감기에 자주 걸리나요?  </td>
                                <td>{user.detailCheck["18"]}</td>
                            </tr>
                            <tr>
                                <td>추위/더위를 많이 타나요? </td>
                                <td>{user.detailCheck["19"]}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <div class="gap30"></div>
                <section>
                    <p class="sub_text"> 상세진단 (소화)</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >

                            <tr>
                                <td>자주 소화가 안되나요? </td>
                                <td>{user.detailCheck["20"]}</td>
                            </tr>
                            <tr>
                                <td>자주 체하는 편인가요? </td>
                                <td>{user.detailCheck["21"]}</td>
                            </tr>
                            <tr>
                                <td>어떤 음식을 먹으면 소화가 더 잘 안되나요?  </td>
                                <td>{user.detailCheck["22"]}</td>
                            </tr>
                            <tr>
                                <td>평소 자주 아래와 같은 소화기 증상이 있나요? </td>
                                <td>{user.detailCheck["23"]}</td>
                            </tr>
                            <tr>
                                <td>입 안에 아래와 같은 증상이 있나요? </td>
                                <td>{user.detailCheck["24"]}</td>
                            </tr>
                            <tr>
                                <td>트림에 관련된 증상이 있나요? </td>
                                <td>{user.detailCheck["25"]}</td>
                            </tr>
                            <tr>
                                <td>가슴쪽 문제가 있나요?  </td>
                                <td>{user.detailCheck["26"]}</td>
                            </tr>
                            <tr>
                                <td>숨이 차거나 한숨을 많이 쉬나요? </td>
                                <td>{user.detailCheck["27"]}</td>
                            </tr>
                            <tr>
                                <td>목에 뭔가가 걸린 것 같은데 잘 뱉어지지 않나요? </td>
                                <td>{user.detailCheck["28"]}</td>
                            </tr>
                            <tr>
                                <td>명치 뒤에 해당하는 등이 결리나요? </td>
                                <td>{user.detailCheck["29"]}</td>
                            </tr>
                            <tr>
                                <td>다크서클이 심한가요? </td>
                                <td>{user.detailCheck["30"]}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <div class="gap30"></div>
                <section>
                    <p class="sub_text"> 상세진단 (음주와 흡연)</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >

                            <tr>
                                <td>간수치가 높은가요? </td>
                                <td>{user.detailCheck["31"]}</td>
                            </tr>
                            <tr>
                                <td>숨이 찬가요? </td>
                                <td>{user.detailCheck["32"]}</td>
                            </tr>
                            <tr>
                                <td>가래가 많이 뱉어지나요? </td>
                                <td>{user.detailCheck["33"]}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <div class="gap30"></div>
                <section>
                    <p class="sub_text"> 상세진단 (화(怒))</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >

                            <tr>
                                <td>성격이 급한 편인가요? </td>
                                <td>{user.detailCheck["34"]}</td>
                            </tr>
                            <tr>
                                <td>머리 및 얼굴에 아래와 같은 증상이 있나요? </td>
                                <td>{user.detailCheck["35"]}</td>
                            </tr>
                            <tr>
                                <td>잠을 잘 자나요?  </td>
                                <td>{user.detailCheck["36"]}</td>
                            </tr>
                            <tr>
                                <td>가슴쪽에 문제가 있나요?  </td>
                                <td>{user.detailCheck["37"]}</td>
                            </tr>
                            <tr>
                                <td>숨이 차거나 한숨을 많이 쉬나요? </td>
                                <td>{user.detailCheck["38"]}</td>
                            </tr>
                            <tr>
                                <td>허리가 아프고 다리에 힘이 없나요? </td>
                                <td>{user.detailCheck["39"]}</td>
                            </tr>
                            <tr>
                                <td>눈에 아래와 같은 증상이 있나요? </td>
                                <td>{user.detailCheck["40"]}</td>
                            </tr>
                            <tr>
                                <td>목에 뭔가가 걸린 것 같은데 잘 뱉어지지 않나요? </td>
                                <td>{user.detailCheck["41"]}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <div class="gap30"></div>
                <section>
                    <p class="sub_text"> 상세진단 (사(思), 우(憂))</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >

                            <tr>
                                <td>자주 피곤하고 쉬어도 피곤이 안 풀리나요? </td>
                                <td>{user.detailCheck["42"]}</td>
                            </tr>
                            <tr>
                                <td>얼굴 빛이 안 좋은가요? </td>
                                <td>{user.detailCheck["43"]}</td>
                            </tr>
                            <tr>
                                <td>소화에 문제가 있나요?  </td>
                                <td>{user.detailCheck["44"]}</td>
                            </tr>
                            <tr>
                                <td>가슴쪽에 문제가 있나요?  </td>
                                <td>{user.detailCheck["45"]}</td>
                            </tr>
                            <tr>
                                <td>요즘 자주 깜빡거리는 것 같나요? </td>
                                <td>{user.detailCheck["46"]}</td>
                            </tr>
                            <tr>
                                <td>식은땀이 나고 열이 올라오는 것 같나요? </td>
                                <td>{user.detailCheck["47"]}</td>
                            </tr>
                            <tr>
                                <td>목에 뭔가가 걸린 것 같은데 잘 뱉어지지 않나요? </td>
                                <td>{user.detailCheck["48"]}</td>
                            </tr>
                            <tr>
                                <td>눈에 아래와 같은 증상이 있나요? </td>
                                <td>{user.detailCheck["49"]}</td>
                            </tr>
                            <tr>
                                <td>목소리가 작고 목소리에 힘이 없다  </td>
                                <td>{user.detailCheck["50"]}</td>
                            </tr>
                            <tr>
                                <td>잠은 잘 자나요?  </td>
                                <td>{user.detailCheck["51"]}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <div class="gap30"></div>
                <section>
                    <p class="sub_text"> 상세진단 (공(恐))</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >

                            <tr>
                                <td>가슴쪽에 문제가 있나요?  </td>
                                <td>{user.detailCheck["52"]}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <div class="gap30"></div>
                <section>
                    <p class="sub_text"> 상세진단 (육체적 노상(勞傷))</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >

                            <tr>
                                <td>근골격계에 문제가 있나요? </td>
                                <td>{user.detailCheck["53"]}</td>
                            </tr>
                            <tr>
                                <td>자주 피곤하고 쉬어도 피곤이 안 풀리나요? </td>
                                <td>{user.detailCheck["54"]}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <div class="gap30"></div>
                <section>
                    <p class="sub_text"> 상세진단 (정신적 노상(勞傷))</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >

                            <tr>
                                <td>소화에 문제가 있나요?  </td>
                                <td>{user.detailCheck["55"]}</td>
                            </tr>
                            <tr>
                                <td>가슴쪽에 문제가 있나요?  </td>
                                <td>{user.detailCheck["56"]}</td>
                            </tr>
                            <tr>
                                <td>요즘 자주 깜빡거리는 것 같나요? </td>
                                <td>{user.detailCheck["57"]}</td>
                            </tr>
                            <tr>
                                <td>식은땀이 나고 열이 올라오는 것 같나요? </td>
                                <td>{user.detailCheck["58"]} </td>
                            </tr>
                            <tr>
                                <td>목에 뭔가가 걸린 것 같은데 잘 뱉어지지 않나요? </td>
                                <td>{user.detailCheck["59"]}</td>
                            </tr>
                            <tr>
                                <td>목소리가 작고 목소리에 힘이 없나요? </td>
                                <td>{user.detailCheck["60"]} </td>
                            </tr>
                            <tr>
                                <td>긴장하면 어떤 현상이 나타나나요?  </td>
                                <td>{user.detailCheck["61"]}</td>
                            </tr>
                            <tr>
                                <td>다크서클이 심한가요? </td>
                                <td>{user.detailCheck["62"]} </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <div class="gap30"></div>
                <section>
                    <p class="sub_text"> 상세진단 (기타)</p>
                    <table border="0" cellspacing="5" cellpadding="5" class="input_page">
                        <tbody >

                            <tr>
                                <td>평소 대변이 어떤가요? </td>
                                <td>{user.detailCheck["63"]}</td>
                            </tr>
                            <tr>
                                <td>어떤 부분에 관심이 많나요?  </td>
                                <td>{user.detailCheck["64"]}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>

            </div>



            <div class="footer">
                <p>© SLOW LIFE TOGETHER</p>
            </div>

        </div>
    );
}

export default AdminStatsWrite;
