import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const TimeoutRedirect = ({redirectPath, children }) => {
  const navigate = useNavigate();
  const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());
  const timeoutDuration = 60000;

  useEffect(() => {
    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        navigate(redirectPath);
      }, timeoutDuration);
    };

    let timeout = setTimeout(() => {
      navigate(redirectPath);
    }, timeoutDuration);

    const handleInteraction = () => {
      console.log('mousemove');
      setLastInteractionTime(Date.now());
      resetTimeout();
    };

    resetTimeout();
    window.addEventListener('mousemove', handleInteraction);
    window.addEventListener('keydown', handleInteraction);
    window.addEventListener('keydown', handleInteraction);
    window.addEventListener('touchstart', handleInteraction);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', handleInteraction);
      window.removeEventListener('keydown', handleInteraction);
      window.removeEventListener('keydown', handleInteraction);
      window.removeEventListener('touchstart', handleInteraction);

    };
  }, [redirectPath]);

  return <>{children}</>;
};

export default TimeoutRedirect;